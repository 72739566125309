import { Box, Checkbox, Typography, styled } from '@mui/material';
import React from 'react';
import { BsCheck as CheckIcon } from 'react-icons/bs';

import noProfilePhoto from '~/assets/no_profile_photo.png';
import { BASE_RESIDENT_PHOTO_PATH } from '~/constants/residents';
import { ResidentsListResponse } from '~/types/residents';

import ResidentAvatar from './ResidentAvatar';

type Props = {
    resident: ResidentsListResponse;
    selected: boolean;
    handleSelect: (resident: ResidentsListResponse) => void;
};

const RowStyle = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: '12px',
    alignItems: 'center',
    border: `1px solid ${theme.palette.grey[300]}`,
    width: '100%',
    height: '64px',
    borderRadius: '8px',
    gap: '8px',
}));

const Label = styled(Typography)(() => ({
    fontSize: '14px',
    textAlign: 'left',
    textWrap: 'balance',
}));

export default function ResidentRowItem({ resident, selected, handleSelect }: Readonly<Props>) {
    const residentName = `${resident.firstName} ${resident.lastName}`;
    const photo = resident.photo ? `${BASE_RESIDENT_PHOTO_PATH}/${resident.photo}` : noProfilePhoto;

    return (
        <RowStyle
            onClick={() => handleSelect(resident)}
            key={resident.residentId}
            sx={{
                backgroundColor: ({ palette }) => (selected ? palette.primary[50] : 'white'),
                border: ({ palette }) =>
                    selected ? `1px solid ${palette.primary[200]}` : `1px solid ${palette.grey[100]}`,
            }}
        >
            <Checkbox
                checked={selected}
                onChange={() => handleSelect(resident)}
                icon={
                    <Box
                        sx={{
                            width: '20px',
                            height: '20px',
                            borderRadius: '50%',
                            bgcolor: 'selector.deselected',
                            border: ({ palette }) => `1px solid ${palette.grey[300]}`,
                        }}
                    />
                }
                checkedIcon={
                    <CheckIcon
                        fill="#F2F8F8"
                        style={{
                            width: '20px',
                            height: '20px',
                            backgroundColor: '#006b75',
                            borderRadius: '50%',
                        }}
                    />
                }
            />
            <ResidentAvatar alt={residentName} src={photo} />
            <Label fontWeight="700">{residentName}</Label>
            <Label variant="h5" color={({ palette }) => palette.secondary[500]}>
                {resident.roomNumber}
            </Label>
        </RowStyle>
    );
}
