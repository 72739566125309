import { useCallback } from 'react';

import { api } from '~/api';
import { useToken } from '~/lib/common';
import { useErrorHandledMutation } from '~/scheduling/api/common';

import { PrnTaskSubmit } from '../types/prnTaskSubmit';

export const useUnscheduledTaskSubmitRequest = () => {
    const token = useToken();

    const mutationFn = useCallback(
        async ({
            prnCategoryId,
            prnSubcategoryId,
            prnActionsTakenIds,
            residentId,
            prnAssistLevelId,
            branchId,
        }: PrnTaskSubmit.RequestBody) => {
            const { data } = await api.post<PrnTaskSubmit.Response>(
                `/prn-tasks/?branch_id=${branchId}`,
                { prnCategoryId, prnSubcategoryId, residentId, prnAssistLevelId, prnActionsTakenIds },
                {
                    headers: {
                        Authorization: token,
                    },
                }
            );

            return data.response;
        },
        [token]
    );

    return useErrorHandledMutation({
        mutationFn,
    });
};
