import { Box, Button, CircularProgress, Typography, styled, useTheme } from '@mui/material';
import React from 'react';

import { UnscheduledTaskDialogSteps } from '~/pages/Home/components/NewUnscheduledTaskDialog/types/unscheduledTaskDialogSteps';

type FooterProps = {
    step: UnscheduledTaskDialogSteps;
    onCancel?: () => void;
    onConfirm?: () => void;
    onAddPRN?: () => void;
    isDisabled?: boolean;
    isLoading?: boolean;
};

const FooterContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 20px 12px',
    height: '80px',
    borderTop: `1px solid ${theme.palette.divider}`,

    [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-end',
        gap: '8px',
    },
}));

const FooterButton = styled(Button)(({ theme }) => ({
    width: '48%',

    [theme.breakpoints.up('md')]: {
        maxWidth: '150px',
    },
}));

export default function Footer({ step, onCancel, onConfirm, onAddPRN, isDisabled, isLoading }: Readonly<FooterProps>) {
    const palette = useTheme().palette;

    const renderButtonContent = (text: string) =>
        isLoading ? (
            <CircularProgress size={24} color="inherit" />
        ) : (
            <Typography fontWeight="bold" fontSize="16px" color="inherit">
                {text}
            </Typography>
        );

    if (step === UnscheduledTaskDialogSteps.SELECT_RESIDENT) {
        return (
            <FooterContainer>
                <FooterButton
                    onClick={onCancel}
                    variant="outlined"
                    size="large"
                    sx={{
                        borderColor: palette.divider,
                    }}
                >
                    <Typography fontWeight="bold" fontSize="16px" color="black">
                        Cancel
                    </Typography>
                </FooterButton>

                <FooterButton
                    onClick={onConfirm}
                    color="primary"
                    variant="contained"
                    size="large"
                    disabled={isDisabled}
                >
                    {renderButtonContent('Confirm')}
                </FooterButton>
            </FooterContainer>
        );
    }

    if (step === UnscheduledTaskDialogSteps.SELECT_ACTIONS_TAKEN) {
        return (
            <FooterContainer>
                <FooterButton
                    onClick={onAddPRN}
                    color="primary"
                    variant="contained"
                    size="large"
                    sx={{ width: '100%' }}
                    disabled={isDisabled}
                >
                    {renderButtonContent('Add PRN')}
                </FooterButton>
            </FooterContainer>
        );
    }

    return null;
}
