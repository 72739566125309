import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material';
import React from 'react';

type Props = {
    label: string;
    onClick: () => void;
};

const Container = styled(Box)(({ theme }) => ({
    padding: '12px',
    width: '100%',
    height: '45px',
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: '8px',
    cursor: 'pointer',
}));

const Label = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[600],
    fontSize: '14px',
    fontWeight: 600,
    textAlign: 'left',
    textWrap: 'balance',
}));

export default function ListItemButton({ label, onClick }: Readonly<Props>) {
    return (
        <Container onClick={onClick}>
            <Label>{label}</Label>
        </Container>
    );
}
