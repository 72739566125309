import { Typography } from '@mui/material';
import React from 'react';

import ButtonGrid from '~/components/Shared/Button/ButtonGrid';
import SquareIconButton from '~/components/Shared/Button/SquareIconButton';

import PrnFlowStep from '../../components/PrnFlowStep';

import useSelectCategoryStep from './hooks/useSelectCategoryStep';
import { PrnTaskCategories } from './types/prnTaskCategories';

export default function SelectCategoryStep({ goToNextStep }: Readonly<{ goToNextStep: () => void }>) {
    const { prnTaskCategories, isLoading, isError, setSelectedCategory } = useSelectCategoryStep();

    const handleSelectCategory = (category: PrnTaskCategories.Category) => {
        setSelectedCategory(category);
        goToNextStep();
    };

    return (
        <PrnFlowStep isLoading={isLoading} isError={isError}>
            <Typography variant="body1" fontWeight={700} mb="8px">
                Select ADL Category
            </Typography>

            <ButtonGrid>
                {prnTaskCategories.map((category) => (
                    <SquareIconButton
                        key={category.id}
                        icon={<category.icon color={'black'} size={24} />}
                        label={category.label}
                        onClick={() => handleSelectCategory(category)}
                    />
                ))}
            </ButtonGrid>
        </PrnFlowStep>
    );
}
