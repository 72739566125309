import {
    Box,
    LinearProgress,
    IconButton as MuiIconButton,
    Stack,
    Typography,
    linearProgressClasses,
    styled,
    useTheme,
} from '@mui/material';
import { ArrowLeft, X } from '@phosphor-icons/react';
import { useAtomValue } from 'jotai';
import React, { ComponentProps, useEffect } from 'react';

import { ShiftSelector } from '~/components/Filtering';
import { UnscheduledTaskDialogSteps } from '~/pages/Home/components/NewUnscheduledTaskDialog/types/unscheduledTaskDialogSteps';

import useShiftSelectorOptions from '../hooks/useShiftSelectorOptions';
import { unscheduledTaskAssistLevelAtom, unscheduledTaskCategoryAtom } from '../state/atom';
import assistLevelWithIconAdapter from '../steps/assistLevel/utils/withIcon';
import categoryWithIconAdapter from '../steps/category/utils/withIcon';
import ResidentAvatar from '../steps/resident/components/ResidentAvatar';
import useResidentInfo from '../steps/resident/hooks/useResidentInfo';

type HeaderProps = {
    onClose: () => void;
    step: UnscheduledTaskDialogSteps;
    shiftId: number | null;
    setShiftId: (shiftId: number) => void;
    onBack?: () => void;
    progress: number;
};

type HeaderContainerProps = {
    onClose: () => void;
    onBack?: () => void;
    children: React.ReactNode;
};

const HeaderContainerBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    height: '72px',
    borderBottom: `1px solid ${theme.palette.divider}`,
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px 24px',

    [theme.breakpoints.up('md')]: {
        height: '86px',
    },
}));

const IconButton = styled(MuiIconButton)(({ theme }) => ({
    maxWidth: '38px',
    height: '38px',
    padding: '10px',
    borderRadius: '50%',
    marginLeft: 'auto',
    [theme.breakpoints.down('md')]: {
        borderColor: 'transparent',
    },
    [theme.breakpoints.up('md')]: {
        border: '1px solid',
        borderColor: theme.palette.divider,
    },
}));

const StepProgressBar = styled(LinearProgress)(({ theme }) => ({
    height: '2px',
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[100],
    },
    [`& .${linearProgressClasses.bar}`]: {
        backgroundColor: theme.palette.primary[500],
    },
}));

const ResidentName = styled(Typography)(() => ({
    fontSize: '14px',
    textAlign: 'left',
    textWrap: 'balance',
    fontWeight: 700,
}));

const RoomNumber = styled(Typography)(() => ({
    fontSize: '14px',
    textAlign: 'left',
    textWrap: 'balance',
}));

const CloseButton = ({ ...props }: ComponentProps<typeof MuiIconButton>) => {
    const { palette } = useTheme();

    return (
        <IconButton {...props}>
            <X color={palette.grey[900]} weight="bold" size={18} />
        </IconButton>
    );
};

const BackButton = ({ ...props }: ComponentProps<typeof MuiIconButton>) => {
    const { palette } = useTheme();

    return (
        <IconButton
            {...props}
            sx={({ breakpoints }) => ({
                marginRight: '4px',
                [breakpoints.up('md')]: {
                    marginRight: 2,
                    border: '1px solid',
                    borderColor: 'transparent',
                },
            })}
        >
            <ArrowLeft color={palette.grey[900]} weight="bold" size={18} />
        </IconButton>
    );
};

const HeaderContainer = ({ onBack, children, onClose }: Readonly<HeaderContainerProps>) => {
    return (
        <HeaderContainerBox>
            <Box display="flex" flexDirection="row" alignItems="center" flexGrow={1}>
                {onBack !== undefined && <BackButton onClick={onBack} />}
                <Box display="flex" flexDirection="row" alignItems="center" flexGrow={1} gap="8px">
                    {children}
                </Box>
            </Box>
            <CloseButton onClick={onClose} />
        </HeaderContainerBox>
    );
};

export default function Header({ onClose, step, shiftId, setShiftId, onBack, progress }: Readonly<HeaderProps>) {
    const { resident, photo } = useResidentInfo();
    const { nearbyShifts, currentShiftId, handleShiftChange } = useShiftSelectorOptions();
    const selectedCategory = useAtomValue(unscheduledTaskCategoryAtom);
    const selectedAssistLevel = useAtomValue(unscheduledTaskAssistLevelAtom);
    const categoryWithIcon = selectedCategory ? categoryWithIconAdapter(selectedCategory) : null;
    const assistLevelWithIcon = selectedAssistLevel ? assistLevelWithIconAdapter(selectedAssistLevel) : null;

    const palette = useTheme().palette;

    useEffect(() => {
        setShiftId(Number(currentShiftId));
    }, [currentShiftId, setShiftId]);

    const isSelectResidentStep = step === UnscheduledTaskDialogSteps.SELECT_RESIDENT;

    return (
        <>
            <HeaderContainer onBack={!isSelectResidentStep ? onBack : undefined} onClose={onClose}>
                <>
                    {isSelectResidentStep ? (
                        <Typography variant="body1" fontSize="16px" fontWeight={700}>
                            Add Task
                        </Typography>
                    ) : (
                        <>
                            {resident && <ResidentAvatar alt={resident?.firstName ?? 'Resident Avatar'} src={photo} />}
                            <Box display="flex" flexDirection="column" gap="4px">
                                <Box display="flex" flexDirection="row" alignItems="center" gap="8px">
                                    <ResidentName>{resident?.firstName}</ResidentName>
                                    <RoomNumber variant="h5" color={({ palette }) => palette.secondary[500]}>
                                        {resident?.roomNumber}
                                    </RoomNumber>
                                </Box>
                                <Box display="flex" flexDirection="row" alignItems="center" gap="8px">
                                    {categoryWithIcon && (
                                        <categoryWithIcon.icon color={palette.primary[600]} weight="bold" size={16} />
                                    )}
                                    {categoryWithIcon && assistLevelWithIcon && (
                                        <Box
                                            sx={{
                                                width: '6px',
                                                height: '6px',
                                                borderRadius: '50%',
                                                backgroundColor: palette.grey[200],
                                            }}
                                        />
                                    )}
                                    {assistLevelWithIcon && (
                                        <assistLevelWithIcon.icon
                                            color={palette.primary[600]}
                                            weight="bold"
                                            size={16}
                                        />
                                    )}
                                </Box>
                            </Box>
                        </>
                    )}
                    <Box flexGrow={isSelectResidentStep ? 1 : 0} ml={isSelectResidentStep ? 2 : 'auto'} mt={1} mr={2}>
                        {shiftId && (
                            <ShiftSelector
                                width="100%"
                                selectedShiftId={shiftId}
                                shiftOptions={nearbyShifts}
                                onChange={(value) => handleShiftChange(value, setShiftId)}
                                isDialog
                            />
                        )}
                    </Box>
                </>
            </HeaderContainer>
            <Stack spacing={2} sx={{ flexGrow: 1 }}>
                <StepProgressBar variant="determinate" value={progress} />
            </Stack>
        </>
    );
}
