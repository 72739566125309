import {
    ArrowsLeftRight,
    CirclesFour,
    ForkKnife,
    Icon,
    Scissors,
    ShieldPlus,
    Shower,
    TShirt,
    Toilet,
    Wheelchair,
} from '@phosphor-icons/react';

import { PrnTaskCategories } from '../types/prnTaskCategories';

export default function withIcon(category: PrnTaskCategories.Category): PrnTaskCategories.Category & { icon: Icon } {
    const iconMap: Record<string, Icon> = {
        Showering: Shower,
        Grooming: Scissors,
        'Eating/Feeding': ForkKnife,
        Dressing: TShirt,
        Toileting: Toilet,
        'Safety ADL': ShieldPlus,
        Mobility: Wheelchair,
        Transfer: ArrowsLeftRight,
        Other: CirclesFour,
    };

    return {
        ...category,
        icon: iconMap[category.label] || CirclesFour,
    };
}
