import { useCallback } from 'react';

import { api } from '~/api';
import { useToken } from '~/lib/common';
import { useErrorHandledQuery } from '~/scheduling/api/common';

import { PrnTaskActionsTaken } from '../types/prnTaskActionsTaken';

// Queries the list of actions to be taken and comments if required
export const useActionsTakenQuery = (params: PrnTaskActionsTaken.RequestParams) => {
    const { branchId, prnCategoryId, prnSubcategoryId, prnAssistLevelId } = params;
    const token = useToken();

    const queryFn = useCallback(async () => {
        const options = {
            headers: { Authorization: token },
            params: { branch_id: branchId, prnCategoryId, prnSubcategoryId, prnAssistLevelId },
        };
        const { data } = await api.get<PrnTaskActionsTaken.Response>(`/prn-tasks/options/actions-taken`, options);

        return data.response;
    }, [branchId, token]);

    return useErrorHandledQuery({
        queryKey: ['unscheduled-task-actions-taken', branchId, prnCategoryId, prnSubcategoryId, prnAssistLevelId],
        queryFn,
        staleTime: 900000,
    });
};
