import { format, parseISO } from 'date-fns';

import { Section } from '~/pages/OperationsDashboard/constants';
import { ChangeOfConditionSectionDetail, SectionDetail, SectionsAsTableType } from '~/types/operationsDashboard';

export const formatDateTime = (dateString: string) => {
    const date = parseISO(dateString);

    return format(date, 'MM/dd, h:mm a');
};

export const formatDay = (dateString: string) => {
    const date = parseISO(dateString);

    return format(date, 'EEE, MMM d');
};

export function isChangeOfConditionDetail(data: SectionDetail): data is ChangeOfConditionSectionDetail {
    return 'isNewEvent' in data;
}

export const DYNAMIC_COLUMNS: Record<SectionsAsTableType, { field: string; headerName: string }[]> = {
    CHANGE_OF_CONDITION: [
        {
            field: 'total',
            headerName: 'Total Changes',
        },
        {
            field: 'careType',
            headerName: 'Care Type',
        },
    ],
    FALLS: [
        {
            field: 'total',
            headerName: 'Total Falls',
        },
        {
            field: 'last30FallCount',
            headerName: 'Multiple in 30d',
        },
    ],
    SHOWER: [
        {
            field: 'total',
            headerName: 'Total not shower',
        },
    ],
    HOSPITAL_TRANSFER: [
        {
            field: 'date',
            headerName: 'Date',
        },
    ],
    FALL_DOCUMENTATION_INCOMPLETE: [
        {
            field: 'fallDateTime',
            headerName: 'Time',
        },
        {
            field: 'reporter',
            headerName: 'Reporter',
        },
    ],
    OVERDUE_ASSESSMENT: [
        {
            field: 'overdueDate',
            headerName: 'Expiration Date',
        },
    ],
    UNSCHEDULED_CARE: [
        {
            field: 'total',
            headerName: 'Total PRNs',
        },
    ],
    RESIDENT_NOTES: [
        {
            field: 'dateTime',
            headerName: 'Date',
        },
        {
            field: 'category',
            headerName: 'Category',
        },
        {
            field: 'reporterName',
            headerName: 'Caregiver',
        },
    ],
    MEDS_REFUSED: [
        {
            field: 'total',
            headerName: 'Doses',
        },
    ],
};

export type DynamicColumnSection = keyof typeof DYNAMIC_COLUMNS;

export const TOTAL_ROW_LABEL: { [key in Section]?: string } = {
    CHANGE_OF_CONDITION: 'change',
    FALLS: 'fall',
    SHOWER: 'time',
    UNSCHEDULED_CARE: 'PRN',
    MEDS_REFUSED: 'doses',
};

export const EXTRA_COLLAPSIBLE_ROW_COLUMNS: { [key in Section]?: string[] } = {
    CHANGE_OF_CONDITION: ['careType'],
    RESIDENT_NOTES: ['dateTime', 'category', 'reporterName'],
    FALLS: ['last30FallCount'],
};

export const DETAILS_COLUMNS_PROPS: { [key in Section]?: string[] } = {
    CHANGE_OF_CONDITION: ['type', 'value', 'careType', 'isNewEvent'],
    FALLS: ['fallDateTime', 'last30FallCount', 'reporterName'],
    SHOWER: ['date', 'reason', 'caregiverName'],
    UNSCHEDULED_CARE: ['prnName', 'caregiver', 'prnDateTime'],
    RESIDENT_NOTES: ['note', 'dateTime', 'category', 'reporterName'],
    MEDS_REFUSED: ['name', 'quantity'],
};

export const DATATYPES_WITH_COLLAPSIBLE_ROWS: Section[] = [
    'CHANGE_OF_CONDITION',
    'FALLS',
    'SHOWER',
    'UNSCHEDULED_CARE',
    'RESIDENT_NOTES',
    'MEDS_REFUSED',
];
