import React from 'react';

import { AlertCard } from './alert';
import { useAlerts } from './useAlerts';

// Obs: This component will change
export const LiveAlerts = () => {
    const { alerts } = useAlerts();

    return alerts.map((alert) => <AlertCard key={alert.id} {...alert} />);
};
