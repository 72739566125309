import { Icon, Person, User, Users } from '@phosphor-icons/react';

import { PrnTaskAssistLevel } from '../types/prnTaskAssistLevel';

export default function withIcon(
    assistLevel: PrnTaskAssistLevel.AssistLevel
): PrnTaskAssistLevel.AssistLevel & { icon: Icon } {
    const iconMap: Record<string, Icon> = {
        '1 Person': User,
        '2 Person': Users,
        'Stand By': Person,
    };

    return {
        ...assistLevel,
        icon: iconMap[assistLevel.label] || Person,
    };
}
