import { Capacitor } from '@capacitor/core';
import { QueryClientProvider } from '@tanstack/react-query-v4';
import React from 'react';
import { Outlet } from 'react-router';

import { v4QueryClient } from '~/api/queries';
import PageTitleHandler from '~/components/Layout/PageTitleHandler';
import GlobalToast from '~/components/Shared/Alerting/Toast/GlobalToast';
import Messages from '~/components/Shared/Messages';
import { OnboardingProvider } from '~/contexts/OnboardingContext';
import NotificationsHandler from '~/helpers/NotificationsHandler';
import { PostHogHandler } from '~/helpers/PostHogHandler';
import PostHogPageViewTracker from '~/router/components/PostHogPageViewTracker';

import { GeolocationProvider } from '../contexts/GeolocationContext';
import AppUrlListener from '../helpers/AppUrlListener';
import LoadingWrapper from '../helpers/LoadingWrapper';

const App = () => (
    <>
        <PostHogPageViewTracker />
        <GeolocationProvider>
            <QueryClientProvider client={v4QueryClient}>
                <OnboardingProvider>
                    {Capacitor.isNativePlatform() && (
                        <>
                            <NotificationsHandler />
                            <AppUrlListener />
                        </>
                    )}
                    <PostHogHandler />
                    <Messages />
                    <GlobalToast />
                    <LoadingWrapper>
                        <PageTitleHandler />
                        <Outlet />
                    </LoadingWrapper>
                </OnboardingProvider>
            </QueryClientProvider>
        </GeolocationProvider>
    </>
);

export default App;
