import { Typography } from '@mui/material';
import React from 'react';

import { ResidentsListResponse } from '~/types/residents';

import PrnFlowStep from '../../components/PrnFlowStep';

import ResidentRowItem from './components/ResidentRowItem';
import useSelectResidentStep from './hooks/useSelectResidentStep';

export default function SelectResidentStep() {
    const { residentsList, isLoading, isError, selectedResident, setSelectedResident } = useSelectResidentStep();

    const handleSelectResident = (resident: ResidentsListResponse) => {
        if (selectedResident?.residentId === resident.residentId) {
            setSelectedResident(null);
            return;
        }

        setSelectedResident(resident);
    };

    return (
        <PrnFlowStep isLoading={isLoading} isError={isError}>
            <Typography variant="body1" fontWeight={700} mb="8px">
                Select Resident
            </Typography>
            {residentsList?.map((resident) => (
                <ResidentRowItem
                    key={resident.residentId}
                    resident={resident}
                    selected={selectedResident?.residentId === resident.residentId}
                    handleSelect={handleSelectResident}
                />
            ))}
        </PrnFlowStep>
    );
}
