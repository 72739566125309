import { Box, ThemeProvider, styled } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';
import React, { useEffect, useMemo } from 'react';

import { UnscheduledTaskDialogSteps } from '~/pages/Home/components/NewUnscheduledTaskDialog/types/unscheduledTaskDialogSteps';
import { queryClient } from '~/scheduling/api/common';
import theme from '~/theme';

import Footer from './components/Footer';
import Header from './components/Header';
import UnscheduledTaskModal from './components/UnscheduledTaskModal';
import useUnscheduledTaskDialog from './hooks/useUnscheduledTaskDialog';
import SelectActionsTakenStep from './steps/actionsTaken/SelectActionsTakenStep';
import SelectAssistLevelStep from './steps/assistLevel/SelectAssistLevelStep';
import SelectCategoryStep from './steps/category/SelectCategoryStep';
import SelectResidentStep from './steps/resident/SelectResidentStep';
import SelectSubcategoryStep from './steps/subcategory/SelectSubcategoryStep';

type NewUnscheduledTaskDialogProps = {
    isOpen: boolean;
    onClose: () => void;
};

const PageContent = styled(Box)(() => ({
    padding: '24px',
}));

function NewUnscheduledTaskDialog({ isOpen, onClose }: Readonly<NewUnscheduledTaskDialogProps>) {
    const { setStep, step, shiftId, setShiftId, isValid, resetFlow, progress, isSubmitPending, onSubmit } =
        useUnscheduledTaskDialog();

    useEffect(() => {
        if (isOpen) {
            resetFlow();
        }
    }, [isOpen]);

    const goToNextStep = () => {
        if (step === UnscheduledTaskDialogSteps.SELECT_RESIDENT) {
            setStep(UnscheduledTaskDialogSteps.SELECT_CATEGORY);
            return;
        }

        if (step === UnscheduledTaskDialogSteps.SELECT_CATEGORY) {
            setStep(UnscheduledTaskDialogSteps.SELECT_SUBCATEGORY);
            return;
        }

        if (step === UnscheduledTaskDialogSteps.SELECT_SUBCATEGORY) {
            setStep(UnscheduledTaskDialogSteps.SELECT_ASSIST_LEVEL);
            return;
        }

        if (step === UnscheduledTaskDialogSteps.SELECT_ASSIST_LEVEL) {
            setStep(UnscheduledTaskDialogSteps.SELECT_ACTIONS_TAKEN);
            return;
        }

        return;
    };

    const goBackOneStep = () => {
        if (step === UnscheduledTaskDialogSteps.SELECT_CATEGORY) {
            setStep(UnscheduledTaskDialogSteps.SELECT_RESIDENT);
            return;
        }

        if (step === UnscheduledTaskDialogSteps.SELECT_SUBCATEGORY) {
            setStep(UnscheduledTaskDialogSteps.SELECT_CATEGORY);
            return;
        }

        if (step === UnscheduledTaskDialogSteps.SELECT_ASSIST_LEVEL) {
            setStep(UnscheduledTaskDialogSteps.SELECT_SUBCATEGORY);
            return;
        }

        if (step === UnscheduledTaskDialogSteps.SELECT_ACTIONS_TAKEN) {
            setStep(UnscheduledTaskDialogSteps.SELECT_ASSIST_LEVEL);
            return;
        }

        return;
    };

    const Content = useMemo(() => {
        switch (step) {
            case UnscheduledTaskDialogSteps.SELECT_RESIDENT:
                return <SelectResidentStep />;
            case UnscheduledTaskDialogSteps.SELECT_CATEGORY:
                return <SelectCategoryStep goToNextStep={goToNextStep} />;
            case UnscheduledTaskDialogSteps.SELECT_SUBCATEGORY:
                return <SelectSubcategoryStep goToNextStep={goToNextStep} />;
            case UnscheduledTaskDialogSteps.SELECT_ASSIST_LEVEL:
                return <SelectAssistLevelStep goToNextStep={goToNextStep} />;
            case UnscheduledTaskDialogSteps.SELECT_ACTIONS_TAKEN:
                return <SelectActionsTakenStep />;
            default:
                return <p>New Unscheduled Task Modal</p>;
        }
    }, [step]);

    return (
        <UnscheduledTaskModal isOpen={isOpen} onClose={onClose}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <Header
                    onClose={onClose}
                    step={step}
                    shiftId={shiftId}
                    setShiftId={setShiftId}
                    onBack={goBackOneStep}
                    progress={progress}
                />
                <PageContent sx={{ flexGrow: 1, overflowY: 'auto', height: '100%' }}>{Content}</PageContent>
                <Footer
                    step={step}
                    onCancel={onClose}
                    onConfirm={goToNextStep}
                    isDisabled={!isValid()}
                    isLoading={isSubmitPending}
                    onAddPRN={onSubmit}
                />
            </Box>
        </UnscheduledTaskModal>
    );
}

const NewUnscheduledTaskDialogWrapper = (props: NewUnscheduledTaskDialogProps) => {
    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <NewUnscheduledTaskDialog {...props} />
            </ThemeProvider>
        </QueryClientProvider>
    );
};

export default NewUnscheduledTaskDialogWrapper;
