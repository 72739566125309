import { Box, Typography } from '@mui/material';
import React from 'react';

import ListItemButton from '~/components/Shared/Button/ListItemButton';

import PrnFlowStep from '../../components/PrnFlowStep';

import useSelectSubcategoryStep from './hooks/useSelectSubcategoryStep';
import { PrnTaskSubcategories } from './types/prnTaskSubcategories';

export default function SelectSubcategoryStep({ goToNextStep }: Readonly<{ goToNextStep: () => void }>) {
    const { prnTaskSubcategories, isLoading, isError, setSelectedSubcategory } = useSelectSubcategoryStep();

    const handleSelectSubcategory = (subcategory: PrnTaskSubcategories.Subcategory) => {
        setSelectedSubcategory(subcategory);
        goToNextStep();
    };

    return (
        <PrnFlowStep isLoading={isLoading} isError={isError}>
            <Typography variant="body1" fontWeight={700} mb="8px">
                Select Category
            </Typography>

            <Box display="flex" flexDirection="column" gap="8px">
                {prnTaskSubcategories?.map((subcategory) => (
                    <ListItemButton
                        key={subcategory.id}
                        label={subcategory.label}
                        onClick={() => handleSelectSubcategory(subcategory)}
                    />
                ))}
            </Box>
        </PrnFlowStep>
    );
}
