import { Box } from '@mui/material';
import { CSSProperties } from '@mui/styles';
import React, { ReactNode } from 'react';

type Props = {
    index: number;
    value: number;
    children: ReactNode;
    TabPanelProps?: CSSProperties;
};

export const CustomTabPanel = (props: Props) => {
    const { children, value, index, TabPanelProps, ...other } = props;

    return (
        <Box width="100%" role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} {...other}>
            {value === index && (
                <Box paddingInline="10px" sx={TabPanelProps}>
                    {children}
                </Box>
            )}
        </Box>
    );
};
