import { Box, Button, Typography, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { PiArrowsHorizontalFill } from 'react-icons/pi';
import { useSelector } from 'react-redux';

import { CustomAvatar } from '~/components/Custom';
import { useTimer } from '~/hooks/useTimer';
import { ReduxStore } from '~/types/redux';

import { ECall } from '../types.d';
import { useAlerts } from '../useAlerts';

import { AlertDetails } from './AlertDetails';

const UnclaimedButton = ({
    requestedAt,
    unclaimedLevel,
    onClick,
}: {
    requestedAt: DateTime;
    unclaimedLevel: ECall.UnclaimedLevel;
    onClick: () => void;
}) => {
    const timer = useTimer(requestedAt);
    const { palette } = useTheme();

    const styles =
        unclaimedLevel === ECall.UnclaimedLevel.Level3
            ? {
                  backgroundColor: palette.error[50] as string,
                  border: `1px solid ${palette.error[900] as string}`,
              }
            : {
                  backgroundColor: palette.warning[200] as string,
                  border: `1px solid ${palette.warning[500]}`,
              };

    return (
        <Box sx={styles} padding="16px" borderRadius="8px" gap="12px" display="flex" flexDirection="column">
            <Typography
                variant="body1"
                fontWeight={700}
                color={
                    unclaimedLevel === ECall.UnclaimedLevel.Level3 ? (palette.error[900] as string) : palette.grey[900]
                }
            >
                Alert
            </Typography>
            <Typography
                variant="body2"
                color={
                    unclaimedLevel === ECall.UnclaimedLevel.Level3 ? (palette.error[900] as string) : palette.grey[900]
                }
            >
                Resident has been waiting for <span style={{ fontWeight: 700 }}>{timer}</span>
            </Typography>
            <Button
                variant={unclaimedLevel === ECall.UnclaimedLevel.Level3 ? 'containedError' : 'warning'}
                onClick={onClick}
            >
                Claim
            </Button>
        </Box>
    );
};

interface StatusBadgeProps {
    requestedAt: DateTime;
    status: ECall.AlertStatus;
    claimedByUser: boolean;
}

const StatusBadge = ({ requestedAt, status, claimedByUser }: StatusBadgeProps) => {
    const { palette } = useTheme();
    const timer = useTimer(requestedAt);

    return (
        <Box
            mt="8px"
            display="flex"
            padding="8px"
            borderRadius="8px"
            justifyContent="space-between"
            bgcolor={claimedByUser ? (palette.secondary[200] as string) : palette.grey[100]}
        >
            <Typography variant="body1" color={palette.grey[900]} fontWeight={700} lineHeight="18px">
                {status === ECall.AlertStatus.Claimed ? 'Claimed' : 'In Progress'}
            </Typography>
            {!claimedByUser && (
                <Typography variant="body1" color={palette.grey[900]} fontWeight={400}>
                    Called {timer} ago
                </Typography>
            )}
        </Box>
    );
};

export const AlertCard = ({
    requestedAt,
    status,
    id,
    unclaimedLevel,
    suggestedLocations,
    currentShiftTasks,
    resident,
    claimedCaregiver,
    startedAt,
}: ECall.Alert) => {
    const { claimAlert } = useAlerts();
    const [showDetails, setShowDetails] = useState(false);
    const { palette } = useTheme();

    const {
        userId,
        firstName: userFirstName,
        lastName: userLastName,
    } = useSelector((state: ReduxStore) => state.session.sessionData);

    const claimedByUser = claimedCaregiver?.id === userId;

    const handleClick = () => {
        if (status !== ECall.AlertStatus.Unclaimed) {
            setShowDetails((prev) => !prev);
        }
    };

    const handleClaimAlert = () => {
        const caregiver: ECall.Caregiver = { id: userId, firstName: userFirstName, lastName: userLastName };
        // TODO - turn this into a backend call
        claimAlert(id, caregiver);
    };

    return (
        <Box>
            <Box
                display="flex"
                flexDirection="column"
                gap="16px"
                padding="16px"
                bgcolor={status !== ECall.AlertStatus.Unclaimed && !claimedByUser ? palette.grey[50] : undefined}
                sx={{
                    borderBottom: 1,
                    borderBottomColor: palette.grey[100],
                    boxShadow: showDetails ? '0px 4px 14.1px 0px rgba(0, 0, 0, 0.10)' : 'none',
                }}
            >
                <Box display="flex" alignItems="center" justifyContent="center" gap="12px" onClick={handleClick}>
                    <CustomAvatar
                        firstName={resident.firstName}
                        lastName={resident.lastName}
                        photo={resident.photo}
                        size={64}
                    />
                    <Box display="flex" flex={1} alignItems="center">
                        <Box flex={1} display="flex" flexDirection="column">
                            <Box flex={1} display="flex" gap="4px" alignItems="center">
                                <Typography fontSize="16px" color={palette.grey[900]} textAlign="left" fontWeight={700}>
                                    {resident.firstName}{' '}
                                    {status === ECall.AlertStatus.Unclaimed
                                        ? resident.lastName
                                        : `${resident.lastName.charAt(0)}.`}
                                </Typography>
                                {status !== ECall.AlertStatus.Unclaimed && (
                                    <>
                                        <PiArrowsHorizontalFill size={16} color={palette.grey[900]} />
                                        <Typography color={palette.grey[900]} textAlign="left" fontWeight={400}>
                                            {claimedByUser
                                                ? 'Me'
                                                : `${claimedCaregiver?.firstName} ${claimedCaregiver?.lastName.charAt(0)}`}
                                        </Typography>
                                    </>
                                )}
                            </Box>
                            <Typography color={palette.secondary.main} fontWeight={700}>
                                Apt {resident.roomNumber ?? 'not set'}
                            </Typography>
                            {status !== ECall.AlertStatus.Unclaimed && (
                                <StatusBadge requestedAt={requestedAt} status={status} claimedByUser={claimedByUser} />
                            )}
                        </Box>
                    </Box>
                    {status !== ECall.AlertStatus.Unclaimed &&
                        (showDetails ? (
                            <FaChevronUp size={20} color={palette.grey[300]} />
                        ) : (
                            <FaChevronDown size={20} color={palette.grey[300]} />
                        ))}
                </Box>
                {status === ECall.AlertStatus.Unclaimed && (
                    <UnclaimedButton
                        requestedAt={requestedAt}
                        unclaimedLevel={unclaimedLevel}
                        onClick={handleClaimAlert}
                    />
                )}
            </Box>

            {showDetails && (
                <AlertDetails
                    claimedByUser={claimedByUser}
                    status={status}
                    roomNumber={resident.roomNumber}
                    suggestedLocations={suggestedLocations}
                    requestedAt={requestedAt}
                    shiftTasks={currentShiftTasks.tasks}
                    currentShift={currentShiftTasks.shiftName}
                    startedAt={startedAt}
                />
            )}
        </Box>
    );
};
