import { v4QueryClient } from '..';
import { useMutation } from '@tanstack/react-query-v4';
import { AxiosError } from 'axios';

import { api } from '~/api';
import { useAppDispatch } from '~/constants/redux';
import { useErrorHandledQuery } from '~/hooks/useErrorHandledQuery';
import { useToken } from '~/lib/common';
import { throwError } from '~/redux/actions/messages';
import { SessionData } from '~/types/session';

export const useLoginInitMutation = () => {
    const dispatch = useAppDispatch();

    return useMutation({
        mutationFn: async (params: { phoneNumber: string }) => {
            await api.post(`/sessions/otp/init`, params);
            return params.phoneNumber;
        },
        onError: (error) => {
            const customError = error as AxiosError;

            dispatch(throwError(customError, true, customError.status !== 404)); // Do not report wrong phone number to Sentry
            throw customError;
        },
        retry: false,
    });
};

export const useLoginVerifyMutation = () => {
    const dispatch = useAppDispatch();

    return useMutation({
        mutationFn: async (params: { phoneNumber: string; code: string }) => {
            const { data } = await api.post(`/sessions/otp/verify`, params);

            return data.response;
        },
        onError: (error) => {
            const customError = error as AxiosError;

            dispatch(throwError(customError, true, customError.status !== 404)); // Do not report wrong OTP to Sentry
            throw customError;
        },
        retry: false,
    });
};

export const useLoginUsernameMutation = ({ onSuccess, onError }) => {
    const dispatch = useAppDispatch();

    type Params = {
        username: string;
        password: string;
        phoneNumber: string;
    };

    return useMutation({
        mutationFn: async (params: Params) => {
            const { data } = await api.post(`/sessions`, params);

            return data.response;
        },
        onSuccess: () => {
            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (error) => {
            if (onError) {
                onError();
            }
            const customError = error as AxiosError;

            dispatch(throwError(customError, true, customError.status !== 404)); // Do not report wrong username to Sentry
            throw customError;
        },
        retry: false,
    });
};

export const useCommunityCodeVerifyMutation = () => {
    const dispatch = useAppDispatch();
    const token = useToken();

    return useMutation({
        mutationFn: async (params: { setupCode: string; latitude: number; longitude: number }) => {
            await api.post(`/users/join-branch`, params, {
                headers: { authorization: token },
            });
        },
        onSuccess: async () => {
            await v4QueryClient.invalidateQueries({ queryKey: ['session'] });
            await v4QueryClient.invalidateQueries({ queryKey: ['permissions'] });
        },
        onError: (error) => {
            const customError = error as AxiosError;

            dispatch(throwError(customError, true, customError.status !== 404)); // Do not report wrong community code to Sentry
            throw customError;
        },
        retry: false,
    });
};

export const useSessionData = () => {
    const token = useToken();
    return useErrorHandledQuery<SessionData>(
        ['session'],
        async (): Promise<SessionData> => {
            const { data } = await api.get('/sessions', {
                headers: { authorization: token },
            });
            return data.response;
        },
        {
            staleTime: 300000,
        }
    );
};
