import { addMinutes } from 'date-fns';
import { isEmpty, omit } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import useSound from 'use-sound';

import { TASK_STATUS, TASK_STATUS_ID_TO_TASK_STATUS } from '@allie/utils/src/constants/task-records.constants';
import { getShiftAtDateTimeUtc } from '@allie/utils/src/shifts';

import { useBranchShifts } from '~/api/queries/branch';
import { useUpdateDailyTasks } from '~/api/queries/tasks/dailyTasks';
import { useDocumentationActionsQuery } from '~/api/queries/tasks/documentationActions';
import notificationSound from '~/assets/notification-sound.mp3';
import AssistLevelChangeDialog from '~/components/Shared/AssistLevelChangeDialog';
import EditTaskStatusDialog from '~/components/Shared/EditTaskStatusDialog';
import NurseCallsDialog from '~/components/Shared/NurseCallsDialog';
import RecentUnscheduledTasksDialog from '~/components/Shared/RecentUnscheduledTasksDialog';
import { AddTaskNotesDialog } from '~/components/Shared/Task/AddTaskNotesDialog';
import { TaskNotesViewDialog } from '~/components/Shared/Task/TaskNotesViewDialog';
import TasksCompletionCountDialog from '~/components/Shared/TasksCompletionCountDialog';
import { EARLY_IN_SHIFT_MINUTES } from '~/constants/home';
import { ONBOARDING } from '~/constants/onboarding';
import { DialogType } from '~/constants/shared';
import {
    checkIfEarlyInShift,
    getCurrentTimeAndShiftStartTime,
    setEarlyInShiftConfirmationExpireTime,
} from '~/lib/common';
import { getDateInUtc } from '~/lib/date';
import EarlyInShiftConfirmationDialog from '~/pages/Residents/Details/components/EarlyInShiftConfirmationDialog';
import {
    DailyTasksShiftDetail,
    ResidentTasks,
    TaskLoadingState,
    TaskToUpdate,
    onConfirmMultipleTasksListInput,
} from '~/types/dailyTasks';
import { ReduxStore } from '~/types/redux';

import { MultiSelectProvider } from './MultiSelectProvider';
import { ResidentRow } from './ResidentRow';

type Props = {
    branchId: number;
    date: string;
    shift: number;
    residentTasksList: ResidentTasks[];
    setSelectedResidentId: (id?: number) => void;
};

export const ResidentRows = (props: Props) => {
    const { residentTasksList, branchId, date, shift, setSelectedResidentId } = props;

    const [loadingTaskStates, setLoadingTaskStates] = useState<{ [key: number]: TaskLoadingState }>({});
    const [loadingBulkState, setLoadingBulkState] = useState<TaskLoadingState>(null);

    const [viewTaskIdNotes, setViewTaskIdNotes] = useState<number | null>(null);

    const [showTaskIdAddNotesDialog, setShowTaskIdAddNotesDialog] = useState<number | null>(null);
    const [taskIdNotes, setTaskIdNotes] = useState<{ [key: number]: string }>({});

    const [showTaskIdRejectDialog, setShowTaskIdRejectDialog] = useState<number | null>(null);

    const [isNurseCallsDialogOpen, setIsNurseCallsDialogOpen] = useState<boolean>(false);
    const [showRecentUnscheduledTasksDialog, setShowRecentUnscheduledTasksDialog] = useState<boolean>(false);

    const [isTasksCompletionCountDialogOpen, setIsTasksCompletionCountDialogOpen] = useState(false);

    const [isAssistLevelChangeDialogOpen, setIsAssistLevelChangeDialogOpen] = useState(false);

    const [lastCompletedTaskResidentId, setLastCompletedTaskResidentId] = useState<number | null>(null);

    const [nurseCallsResidentId, setNurseCallsResidentId] = useState<number | null>(null);

    const [taskIdCompletionOptions, setTaskIdCompletionOptions] = useState<{
        [key: string]: number;
    }>({});

    const [documentationActionsTaskIds, setDocumentationActionsTaskIds] = useState<number[]>([]);

    const [isEarlyInShiftConfirmationDialogOpen, setIsEarlyInShiftConfirmationDialogOpen] = useState<boolean>(false);
    const [confirmTaskParams, setConfirmTaskParams] = useState<{
        taskId: number;
        taskStatusId: number;
    } | null>(null);
    const [confirmSelectedTasksParams, setConfirmSelectedTasksParams] = useState<number | null>(null);
    const [rejectTaskDialogParams, setRejectTaskDialogParams] = useState<number | null>(null);

    const [playCoinSound] = useSound(notificationSound);

    const updateTaskMutation = useUpdateDailyTasks();

    const branchShifts = useBranchShifts(branchId);

    const { timezone } = useSelector((state: ReduxStore) => state.session);

    const {
        shift: { name: currentShiftName },
    } = getShiftAtDateTimeUtc(getDateInUtc(new Date()), timezone, branchShifts);

    const taskIdToTask: Record<number, DailyTasksShiftDetail> = useMemo(
        () =>
            residentTasksList.reduce((acc, { tasks }) => {
                tasks.forEach((task) => {
                    acc[task.dailyTaskRecordId] = task;
                });

                return acc;
            }, {}),
        [residentTasksList]
    );

    const onToggleTaskNotes = (taskId = null) => {
        setViewTaskIdNotes(taskId);
    };

    const onToggleEditAddTaskNotes = (taskId = null) => {
        setShowTaskIdAddNotesDialog(taskId);
    };

    const onSubmitEditAddTaskNotes = (taskNotes: string) => {
        // To make TS happy that it isn't null
        if (!showTaskIdAddNotesDialog) {
            return;
        }

        const newTaskNotes = {
            ...taskIdNotes,
            [showTaskIdAddNotesDialog]: taskNotes,
        };

        setTaskIdNotes(newTaskNotes);
        setShowTaskIdAddNotesDialog(null);
    };

    const onToggleRejectDialog = (taskId = null) => {
        setShowTaskIdRejectDialog(taskId);
    };

    const toggleNurseCallsDialog = () => {
        setIsNurseCallsDialogOpen((prevState) => !prevState);
    };

    const toggleRecentUnscheduledTasksDialog = () => {
        setShowRecentUnscheduledTasksDialog((prevState) => !prevState);
    };

    const toggleTasksCompletionCountDialog = () => {
        setIsTasksCompletionCountDialogOpen((prevState) => !prevState);
    };

    const toggleAssistLevelChangeDialog = () => {
        setIsAssistLevelChangeDialogOpen((prevState) => !prevState);
    };

    const toggleEarlyInShiftConfirmationDialog = () => {
        setIsEarlyInShiftConfirmationDialogOpen((prevState) => !prevState);
    };

    const onCompletionOptionsChange = (taskId: number, completionValue: number) => {
        setTaskIdCompletionOptions({
            ...taskIdCompletionOptions,
            [taskId]: completionValue,
        });
    };

    const { data: documentationActionsData } = useDocumentationActionsQuery({
        branchId,
        taskIds: [...documentationActionsTaskIds],
    });

    const checkAssistLevelChangeDialog = () => {
        if (documentationActionsData && !isEmpty(documentationActionsData.showAssistLevelChange)) {
            setIsAssistLevelChangeDialogOpen(true);
        }
    };

    const checkTaskCompletionCountDialog = () => {
        if (documentationActionsData && documentationActionsData.showTaskCompletionCount.length > 0) {
            setIsTasksCompletionCountDialogOpen(true);
        } else {
            checkAssistLevelChangeDialog();
        }
    };

    useEffect(() => {
        if (!documentationActionsData) return;
        if (isEarlyInShiftConfirmationDialogOpen) return;

        if (documentationActionsData.recentUnscheduledTasks.length > 0) {
            setShowRecentUnscheduledTasksDialog(true);
            return;
        }

        if (documentationActionsData.showNurseCallsActions) {
            setIsNurseCallsDialogOpen(true);
        } else {
            checkTaskCompletionCountDialog();
        }
    }, [documentationActionsTaskIds, documentationActionsData, isEarlyInShiftConfirmationDialogOpen]);

    const onConfirmTask = async (taskId: number, taskStatusId: number, notes = '') => {
        const taskStatus = TASK_STATUS_ID_TO_TASK_STATUS[taskStatusId] as keyof typeof TASK_STATUS;
        const tasksToUpdate: TaskToUpdate[] = [];

        tasksToUpdate.push({
            taskId,
            taskStatusId,
            caregiverNotes: notes || taskIdNotes[taskId],
        });

        setLoadingTaskStates((prevState) => ({
            ...prevState,
            [taskId]: taskStatus === TASK_STATUS.COMPLETED ? 'confirm' : 'reject',
        }));

        await updateTaskMutation.mutateAsync({ tasks: tasksToUpdate, branchId });

        playCoinSound();

        setDocumentationActionsTaskIds([taskId]);

        setLoadingTaskStates((prevState) => omit(prevState, taskId));
    };

    const onConfirmMultipleTasks = async (onConfirmMultipleTasksList: onConfirmMultipleTasksListInput, notes = '') => {
        const taskIds = onConfirmMultipleTasksList.map((task) => task.taskId);
        const tasksToUpdate: TaskToUpdate[] = [];

        onConfirmMultipleTasksList.forEach(({ taskId, taskStatusId }) => {
            let notesToUse = notes;
            if (taskIdNotes[taskId]) {
                notesToUse = taskIdNotes[taskId];
            }

            tasksToUpdate.push({
                taskId,
                taskStatusId,
                caregiverNotes: notesToUse,
            });
        });

        setLoadingTaskStates((prevState) => ({
            ...prevState,
            ...Object.fromEntries(taskIds.map((taskId) => [taskId, 'disable'])),
        }));

        // Assuming every task has the same status
        const taskStatus = TASK_STATUS_ID_TO_TASK_STATUS[tasksToUpdate[0].taskStatusId] as keyof typeof TASK_STATUS;
        setLoadingBulkState(taskStatus === TASK_STATUS.COMPLETED ? 'confirm' : 'reject');

        await updateTaskMutation.mutateAsync({ tasks: tasksToUpdate, branchId });

        playCoinSound();

        setDocumentationActionsTaskIds(onConfirmMultipleTasksList.map((task) => task.taskId));

        setLoadingTaskStates((prevState) => omit(prevState, taskIds));
        setLoadingBulkState(null);
    };

    return (
        <>
            {residentTasksList.length > 0 && (
                <MultiSelectProvider
                    setSelectedResidentIdForAddedTaskDialog={setSelectedResidentId}
                    residentTasksList={residentTasksList}
                    render={(
                        openedResidentId,
                        selectedResidentId,
                        residentSelectedTaskIds,
                        onResidentToggle,
                        onResidentSelect,
                        onResidentTaskSelect
                    ) => {
                        const confirmSelectedTasks = (taskStatusId: number, notes = '') => {
                            const tasks = Array.from(residentSelectedTaskIds).map((taskId) => ({
                                taskId,
                                taskStatusId,
                            }));
                            void onConfirmMultipleTasks(tasks, notes);
                        };

                        const onRejectDialogSubmit = (taskStatusId: number, notes = '') => {
                            if (showTaskIdRejectDialog === -1) {
                                confirmSelectedTasks(taskStatusId, notes);
                            } else if (showTaskIdRejectDialog) {
                                void onConfirmTask(showTaskIdRejectDialog, taskStatusId, notes);
                            }
                            setShowTaskIdRejectDialog(null);
                        };

                        const handleAcceptEarlyInShift = () => {
                            toggleEarlyInShiftConfirmationDialog();
                            const { shiftStartTime } = getCurrentTimeAndShiftStartTime(timezone, branchShifts);
                            setEarlyInShiftConfirmationExpireTime(
                                addMinutes(shiftStartTime, EARLY_IN_SHIFT_MINUTES).getTime()
                            );
                            if (confirmTaskParams) {
                                onConfirmTask(confirmTaskParams.taskId, confirmTaskParams.taskStatusId);
                            } else if (confirmSelectedTasksParams) {
                                confirmSelectedTasks(confirmSelectedTasksParams);
                            } else if (rejectTaskDialogParams) {
                                setShowTaskIdRejectDialog(rejectTaskDialogParams);
                            }
                            setConfirmTaskParams(null);
                            setConfirmSelectedTasksParams(null);
                            setRejectTaskDialogParams(null);
                        };

                        return (
                            <>
                                {residentTasksList.map((residentTasks) => (
                                    <ResidentRow
                                        key={residentTasks.id}
                                        isSelected={selectedResidentId === residentTasks.id}
                                        isOpen={openedResidentId === residentTasks.id}
                                        residentTasks={residentTasks}
                                        selectedTaskIds={residentSelectedTaskIds}
                                        loadingTaskStates={loadingTaskStates}
                                        loadingBulkState={loadingBulkState}
                                        onResidentToggle={onResidentToggle}
                                        onResidentSelectToggle={onResidentSelect}
                                        onResidentTaskSelectToggle={onResidentTaskSelect}
                                        onToggleTaskNotes={onToggleTaskNotes}
                                        onToggleEditAddTaskNotes={onToggleEditAddTaskNotes}
                                        onToggleRejectDialog={(taskId: number) => {
                                            setNurseCallsResidentId(residentTasks.id);
                                            if (checkIfEarlyInShift(timezone, branchShifts)) {
                                                toggleEarlyInShiftConfirmationDialog();
                                                setRejectTaskDialogParams(taskId);
                                                return;
                                            }
                                            setShowTaskIdRejectDialog(taskId);
                                        }}
                                        onCompletionOptionsChange={onCompletionOptionsChange}
                                        taskIdCompletionOptions={taskIdCompletionOptions}
                                        taskIdNotes={taskIdNotes}
                                        onConfirmTask={(taskId, taskStatusId) => {
                                            setNurseCallsResidentId(residentTasks.id);
                                            setLastCompletedTaskResidentId(residentTasks.id);

                                            if (checkIfEarlyInShift(timezone, branchShifts)) {
                                                toggleEarlyInShiftConfirmationDialog();
                                                setConfirmTaskParams({
                                                    taskId,
                                                    taskStatusId,
                                                });
                                                return;
                                            }

                                            void onConfirmTask(taskId, taskStatusId);
                                        }}
                                        confirmSelectedTasks={(taskStatusId) => {
                                            setNurseCallsResidentId(residentTasks.id);
                                            setLastCompletedTaskResidentId(residentTasks.id);
                                            if (checkIfEarlyInShift(timezone, branchShifts)) {
                                                toggleEarlyInShiftConfirmationDialog();
                                                setConfirmSelectedTasksParams(taskStatusId);
                                                return;
                                            }
                                            confirmSelectedTasks(taskStatusId);
                                        }}
                                        date={date}
                                        shift={shift}
                                        branchId={branchId}
                                    />
                                ))}
                                <TaskNotesViewDialog
                                    isOpen={viewTaskIdNotes !== null}
                                    taskNote={viewTaskIdNotes ? taskIdToTask[viewTaskIdNotes].taskNotes : ''}
                                    onClose={() => onToggleTaskNotes()}
                                />
                                <AddTaskNotesDialog
                                    isOpen={showTaskIdAddNotesDialog !== null}
                                    onClose={() => onToggleEditAddTaskNotes()}
                                    onSubmit={onSubmitEditAddTaskNotes}
                                    taskNote={showTaskIdAddNotesDialog ? taskIdNotes[showTaskIdAddNotesDialog] : ''}
                                />
                                <EditTaskStatusDialog
                                    onboardingId={ONBOARDING.REFUSE_TASK_REASON.TARGET}
                                    isOpen={showTaskIdRejectDialog !== null}
                                    onClose={onToggleRejectDialog}
                                    onSubmit={(taskStatusId, notes) => {
                                        onRejectDialogSubmit(taskStatusId, notes);
                                    }}
                                    taskNotes={showTaskIdRejectDialog ? taskIdNotes[showTaskIdRejectDialog] : undefined}
                                    dialogType={DialogType.Reject}
                                />
                                <EarlyInShiftConfirmationDialog
                                    isOpen={isEarlyInShiftConfirmationDialogOpen}
                                    onClose={() => {
                                        setShowTaskIdRejectDialog(null);
                                        setNurseCallsResidentId(null);
                                        setLastCompletedTaskResidentId(null);
                                        setConfirmTaskParams(null);
                                        setConfirmSelectedTasksParams(null);
                                        setRejectTaskDialogParams(null);
                                        toggleEarlyInShiftConfirmationDialog();
                                    }}
                                    shiftName={currentShiftName}
                                    onAccept={handleAcceptEarlyInShift}
                                />
                            </>
                        );
                    }}
                />
            )}
            {nurseCallsResidentId && documentationActionsData?.showNurseCallsActions && (
                <NurseCallsDialog
                    isOpen={isNurseCallsDialogOpen}
                    onClose={() => {
                        toggleNurseCallsDialog();
                        setNurseCallsResidentId(null);
                        checkTaskCompletionCountDialog();
                    }}
                    residentId={nurseCallsResidentId}
                    date={date}
                    shift={shift}
                />
            )}
            {nurseCallsResidentId && (
                <RecentUnscheduledTasksDialog
                    isOpen={showRecentUnscheduledTasksDialog}
                    tasks={documentationActionsData?.recentUnscheduledTasks ?? []}
                    onClose={() => {
                        toggleRecentUnscheduledTasksDialog();
                        setNurseCallsResidentId(null);
                        checkTaskCompletionCountDialog();
                    }}
                    residentId={nurseCallsResidentId}
                    date={date}
                    shift={shift}
                />
            )}
            {documentationActionsData?.showTaskCompletionCount && lastCompletedTaskResidentId && (
                <TasksCompletionCountDialog
                    isOpen={isTasksCompletionCountDialogOpen}
                    onClose={() => {
                        toggleTasksCompletionCountDialog();
                        checkAssistLevelChangeDialog();
                    }}
                    tasksCompletionCountData={documentationActionsData.showTaskCompletionCount}
                    residentId={lastCompletedTaskResidentId}
                    date={date}
                    shift={shift}
                />
            )}
            {documentationActionsData?.showAssistLevelChange && lastCompletedTaskResidentId && (
                <AssistLevelChangeDialog
                    isOpen={isAssistLevelChangeDialogOpen}
                    onClose={() => {
                        toggleAssistLevelChangeDialog();
                        setLastCompletedTaskResidentId(null);
                    }}
                    assistLevelChangeData={documentationActionsData.showAssistLevelChange}
                    residentId={lastCompletedTaskResidentId}
                />
            )}
        </>
    );
};
