import { Capacitor } from '@capacitor/core';
import { Box, Button, Link, Typography, styled } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { isValid, subMonths } from 'date-fns';
import React, { useState } from 'react';
import { FaLaptop } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Thread, spawn } from 'threads';

import { useBranchesByRegionQuery } from '~/api/queries/companyInfo';
import { useResidentsQuery } from '~/api/queries/residents';
import { useExportShiftNotesCsvQuery } from '~/api/queries/shiftNotes/exportCsv';
import { useExportTaskRecordsCsvQuery } from '~/api/queries/tasks/exportCsv';
import { useFlowSheetDataQuery } from '~/api/queries/tasks/flowSheet';
import { CustomSelect } from '~/components/Custom';
import { INPUT_STYLES } from '~/components/Custom/constants';
import { BranchSelector } from '~/components/Filtering';
import { pxToRem } from '~/components/theme/typography';
import PageStructure from '~/pages/PageStructure';
import { ExportTasksDataTypes } from '~/types/dailyTasks';
import { ResidentTasksResponse } from '~/types/flowSheet';
import { ReduxStore } from '~/types/redux';

import { ExportDataTypes, downloadBlob, mapFileNameGenerator, safeFormatDate } from './helpers';

// function to interface with the FlowSheet render service worker
const renderFlowSheet = async (residents: ResidentTasksResponse, startDate: Date, endDate: Date) => {
    const renderWorker = await spawn<(residents: ResidentTasksResponse, startDate: Date, endDate: Date) => Blob>(
        new Worker(new URL('./FlowSheet/render-worker', import.meta.url))
    );
    const pdfBlob = await renderWorker(residents, startDate, endDate);

    await Thread.terminate(renderWorker);

    return pdfBlob;
};

const dataTypeOptions = [
    { label: 'Shift Notes', value: ExportDataTypes.SHIFT_NOTES },
    { label: 'Task Records', value: ExportDataTypes.TASK_RECORDS },
    { label: 'Regulator Flow Sheet', value: ExportDataTypes.REGULATOR_FLOW_SHEET },
];

const NoNativePageBody = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        color: 'primary.main',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: pxToRem(32),
        p: pxToRem(32),
    })
);

const NoNativeTypography = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        color: '#6F6F79',
        lineHeight: 1.7,
        fontSize: pxToRem(16),
        textAlign: 'center',
    })
);

const NoNativeLink = styled(Link)(({ theme }) =>
    theme.unstable_sx({
        color: 'primary.dark',
        fontWeight: 600,
    })
);

const PageBody = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        maxWidth: { xs: '100%', lg: pxToRem(600) },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: pxToRem(16),
        pt: pxToRem(8),
    })
);

const DatePickerRow = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'row',
        gap: pxToRem(12),
        ...INPUT_STYLES,
        '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
            transform: 'translate(16px, 8px) !important',
        },
        '& .MuiInputBase-root': {
            padding: '0 12px 0 0 !important',
        },
        '& .MuiInputBase-input': {
            padding: '8px 16px !important',
        },
    })
);

const ExportData = () => {
    const isNativePlatform = Capacitor.isNativePlatform();

    const { sessionData } = useSelector((state: ReduxStore) => state.session);

    const today = new Date();
    const defaultStartDate = subMonths(today, 1);
    const defaultEndDate = today;
    const defaultResidentId = 0; // all residents

    const [dataType, setDataType] = useState<ExportDataTypes>(ExportDataTypes.TASK_RECORDS);
    const [selectedResidentId, setSelectedResidentId] = useState(defaultResidentId);
    const [branchId, setBranchId] = useState<number>(sessionData.branchId!);
    const [startDate, setStartDate] = useState<Date>(defaultStartDate);
    const [endDate, setEndDate] = useState<Date>(defaultEndDate);
    const [dateError, setDateError] = useState<{ startDate: boolean; endDate: boolean }>({
        startDate: false,
        endDate: false,
    });

    const [isFileDownloadLoading, setIsFileDownloadLoading] = useState(false);

    const { data: branchesByRegionData } = useBranchesByRegionQuery({ branchId: sessionData.branchId });
    const branchesWithAccess = branchesByRegionData?.branches.filter((branch) => branch.hasAccess) ?? [];
    const branchIdsToBranchNames = Object.fromEntries(
        branchesWithAccess.map((branch) => [branch.branchId, branch.branchName]) ?? []
    );

    const { data: residents, error, isLoading: isResidentsLoading } = useResidentsQuery({ branchId: branchId });
    const defaultResidentOption = { value: `${defaultResidentId}`, label: 'All' };
    const listOfResidents = [
        defaultResidentOption,
        ...(!isResidentsLoading && !error && residents ? residents : [])
            .map((resident) => ({
                label: `${resident.firstName} ${resident.lastName}`,
                value: resident.residentId.toString(),
            }))
            .sort((a, b) => a.label.localeCompare(b.label)),
    ];

    const formattedStartDate = safeFormatDate(startDate);
    const formattedEndDate = safeFormatDate(endDate);

    const { refetch: fetchExportShiftNotesCsv } = useExportShiftNotesCsvQuery({
        branchId,
        startDate: formattedStartDate!,
        endDate: formattedEndDate!,
        residentId: selectedResidentId || undefined,
    });

    const { refetch: fetchExportTaskRecordsCsv } = useExportTaskRecordsCsvQuery({
        branchId,
        startDate: formattedStartDate!,
        endDate: formattedEndDate!,
        residentId: selectedResidentId || undefined,
        // This cast is necessary because, for the typescript, the value of dataType could be "SHIFT_NOTES",
        // but actually it will always be a value of ExportTasksDataTypes (as the function expects).
        // We are ensuring that in the lines 150 and 151.
        type: dataType as unknown as ExportTasksDataTypes,
    });

    const { refetch: fetchRegulatorFlowSheet } = useFlowSheetDataQuery({
        branchId,
        startDate: formattedStartDate!,
        endDate: formattedEndDate!,
        residentId: selectedResidentId || undefined,
    });

    const handleSubmit = async () => {
        const handler = async () => {
            const mapDataTypeToQueryHandler: Record<
                ExportDataTypes,
                () => Promise<{ data: ArrayBuffer | ResidentTasksResponse | undefined }>
            > = {
                [ExportDataTypes.SHIFT_NOTES]: fetchExportShiftNotesCsv,
                [ExportDataTypes.TASK_RECORDS]: fetchExportTaskRecordsCsv,
                [ExportDataTypes.REGULATOR_FLOW_SHEET]: fetchRegulatorFlowSheet,
            };

            if (!isValid(startDate) || !isValid(endDate)) return;

            const queryHandler = mapDataTypeToQueryHandler[dataType];

            const response = await queryHandler();

            if (!response.data) return;

            if (dataType === ExportDataTypes.REGULATOR_FLOW_SHEET) {
                const pdfBlob = await renderFlowSheet(response.data as ResidentTasksResponse, startDate, endDate);
                downloadFileFromBlob(pdfBlob);
            } else {
                const csvBlob = new Blob([response.data as ArrayBuffer], { type: 'text/csv' });
                downloadFileFromBlob(csvBlob);
            }
        };

        setIsFileDownloadLoading(true);
        await handler();
        setIsFileDownloadLoading(false);
    };

    const downloadFileFromBlob = (fileBlob: Blob) => {
        const resident = residents?.find((r) => r.residentId === selectedResidentId);
        const fileNameGenerator = mapFileNameGenerator[dataType];
        const filename = fileNameGenerator(
            branchIdsToBranchNames[branchId],
            startDate,
            endDate,
            resident && `${resident.firstName} ${resident.lastName}`
        );
        downloadBlob(fileBlob, filename);
    };

    const shouldDisableSubmitButton =
        dateError.endDate || dateError.startDate || !startDate || !endDate || isFileDownloadLoading;

    // A native version would need @capacitor/filesystem to work, but is not really useful
    if (isNativePlatform) {
        return (
            <PageStructure>
                <NoNativePageBody>
                    <FaLaptop size={pxToRem(100)} />
                    <NoNativeTypography>
                        Data export is not available on the mobile app. Please use the web version at{' '}
                        <NoNativeLink href="https://alliehealth.co">https://alliehealth.co</NoNativeLink>
                    </NoNativeTypography>
                </NoNativePageBody>
            </PageStructure>
        );
    }

    return (
        <PageStructure>
            <PageBody>
                <CustomSelect
                    id="data-type"
                    label="Data Type"
                    value={dataType}
                    options={dataTypeOptions}
                    onChange={(newValue) => setDataType(newValue as ExportDataTypes)}
                    fullWidth
                    hideIfEmpty={false}
                />
                <BranchSelector
                    value={branchId}
                    options={branchesWithAccess
                        .map((branch) => ({
                            label: branch.branchName,
                            value: branch.branchId.toString(),
                        }))
                        .sort((a, b) => a.label.localeCompare(b.label))}
                    onChange={(newValue) => {
                        setSelectedResidentId(defaultResidentId);
                        setBranchId(newValue as number);
                    }}
                    fullWidth
                />
                <CustomSelect
                    id="resident"
                    label="Resident"
                    value={selectedResidentId ?? ''}
                    options={listOfResidents}
                    onChange={(newValue) => setSelectedResidentId(+newValue)}
                    fullWidth
                    hideIfEmpty={false}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePickerRow>
                        <DatePicker
                            label="Start Date"
                            onError={(reason) => setDateError((prev) => ({ ...prev, startDate: !!reason }))}
                            value={startDate}
                            maxDate={endDate}
                            onChange={(value) => value && setStartDate(value)}
                        />
                        <DatePicker
                            label="End Date"
                            onError={(reason) => setDateError((prev) => ({ ...prev, endDate: !!reason }))}
                            value={endDate}
                            minDate={startDate}
                            maxDate={today}
                            onChange={(value) => value && setEndDate(value)}
                        />
                    </DatePickerRow>
                </LocalizationProvider>
                <Button
                    variant="contained"
                    type="button"
                    size="small"
                    onClick={() => void handleSubmit()}
                    fullWidth
                    disabled={shouldDisableSubmitButton}
                >
                    {isFileDownloadLoading ? 'Generating Document...' : 'Export'}
                </Button>
            </PageBody>
        </PageStructure>
    );
};

export default ExportData;
