import { useCallback } from 'react';

import { api } from '~/api';
import { useToken } from '~/lib/common';
import { useErrorHandledQuery } from '~/scheduling/api/common';

import { PrnTaskAssistLevel } from '../types/prnTaskAssistLevel';

// Queries the list of assist level based on the prn category id, branch id and subcategory id
export const useAssistLevelQuery = (params: PrnTaskAssistLevel.RequestParams) => {
    const { branchId, prnCategoryId, prnSubcategoryId } = params;
    const token = useToken();

    const queryFn = useCallback(async () => {
        const options = {
            headers: { Authorization: token },
            params: { branch_id: branchId, prnCategoryId, prnSubcategoryId },
        };
        const { data } = await api.get<PrnTaskAssistLevel.Response>(`/prn-tasks/options/assist-level`, options);

        return data.response;
    }, [branchId, token]);

    return useErrorHandledQuery({
        queryKey: ['unscheduled-task-assist-level', branchId, prnCategoryId, prnSubcategoryId],
        queryFn,
        staleTime: 900000,
    });
};
