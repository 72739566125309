import { useAtom } from 'jotai';
import { useSelector } from 'react-redux';

import { ReduxStore } from '~/types/redux';

import {
    unscheduledTaskActionsTakenAtom,
    unscheduledTaskActionsTakenNotesAtom,
    unscheduledTaskAssistLevelAtom,
    unscheduledTaskCategoryAtom,
    unscheduledTaskIsMissingRequiredCommentAtom,
    unscheduledTaskSubcategoryAtom,
} from '../../../state/atom';

import { useActionsTakenQuery } from './useActionsTakenQuery';

export default function useSelectActionsTakenStep() {
    const { branchId } = useSelector((state: ReduxStore) => state.session.sessionData);
    const [selectedCategory] = useAtom(unscheduledTaskCategoryAtom);
    const [selectedSubcategory] = useAtom(unscheduledTaskSubcategoryAtom);
    const [selectedAssistLevel] = useAtom(unscheduledTaskAssistLevelAtom);
    const [selectedActionsTaken, setSelectedActionsTaken] = useAtom(unscheduledTaskActionsTakenAtom);
    const [actionsTakenNotes, setActionsTakenNotes] = useAtom(unscheduledTaskActionsTakenNotesAtom);
    const [isMissingRequiredComment, setIsMissingRequiredComment] = useAtom(
        unscheduledTaskIsMissingRequiredCommentAtom
    );

    const {
        data: prnTaskActionsTaken,
        isLoading,
        isError,
    } = useActionsTakenQuery({
        branchId: branchId!,
        prnCategoryId: selectedCategory?.id ?? 1,
        prnAssistLevelId: selectedAssistLevel?.id,
        prnSubcategoryId: selectedSubcategory?.id,
    });

    return {
        prnTaskActionsTaken,
        isLoading,
        isError,
        selectedActionsTaken,
        setSelectedActionsTaken,
        actionsTakenNotes,
        setActionsTakenNotes,
        isMissingRequiredComment,
        setIsMissingRequiredComment,
    };
}
