import { useAtom } from 'jotai';

import noProfilePhoto from '~/assets/no_profile_photo.png';
import { BASE_RESIDENT_PHOTO_PATH } from '~/constants/residents';

import { unscheduledTaskResidentAtom } from '../../../state/atom';

export default function useResidentInfo() {
    const [resident] = useAtom(unscheduledTaskResidentAtom);

    const photo = resident?.photo ? `${BASE_RESIDENT_PHOTO_PATH}/${resident.photo}` : noProfilePhoto;

    return {
        resident,
        photo,
    };
}
