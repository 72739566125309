import { Box, Tab, Tabs, styled, useMediaQuery, useTheme } from '@mui/material';
import React, { useMemo } from 'react';

import { DailyTasksByTabStrict, TabKey, TabName } from '~/types/dailyTasks';

const MobileTabs = styled(Tabs)(({ theme }) => ({
    '& .MuiTab-root': {
        color: '#ffffffd0 !important',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: 1.5,
        '&.Mui-selected': {
            color: 'white !important',
            fontWeight: 700,
        },
    },

    '& .MuiTabs-flexContainer': {
        width: 'max(375px, 100%)',
        gap: '16px',
    },

    '& .MuiTabs-scroller': {
        overflowX: 'auto !important',

        // hide scrollbar
        '-ms-overflow-style': 'none',
        scrollbarWidth: 'none',

        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },

    '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.common.white,
    },
}));

const DesktopTabs = styled(Tabs)(({ theme }) => ({
    overflowY: 'auto',
    paddingTop: '0 !important',

    '& .MuiTab-root': {
        color: `${theme.palette.grey[500]} !important`,
        paddingInline: '16px',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: 1.2,
        '&.Mui-selected': {
            color: 'black !important',
            fontWeight: 700,
        },
    },
}));

type TabProps = {
    tabs: DailyTasksByTabStrict;
    selectedTab: TabKey;
    onTabChange: (tabKey: TabKey) => void;
};

const friendlyTabName: Record<TabName, string> = {
    pending: 'To do',
    completed: 'Done',
    alerts: 'Live Alerts',
};

export const TabSelector = ({ tabs, selectedTab, onTabChange }: TabProps) => {
    const { breakpoints } = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('sm'));

    const selectedTabIndex = useMemo(() => tabs.findIndex(({ key }) => key === selectedTab), [tabs, selectedTab]);

    const handleTabIndexChange = (tabIndex: number) => {
        const newTabKey = tabs[tabIndex].key;
        onTabChange(newTabKey);
    };

    const Tabs = isMobile ? MobileTabs : DesktopTabs;

    return (
        <Tabs
            sx={{ mt: '8px', mx: '18px' }}
            value={selectedTabIndex}
            onChange={(_, tabIndex) => handleTabIndexChange(tabIndex as number)}
        >
            {tabs.map(({ key, tabName, tasks }) => {
                const isSelectedTab = key === selectedTab;

                return (
                    <Tab
                        key={key}
                        label={friendlyTabName[tabName]}
                        icon={
                            <Box
                                sx={({ palette }) => ({
                                    ...(isMobile
                                        ? {
                                              color: isSelectedTab ? (palette.primary[500] as string) : '#ffffffd0',
                                              backgroundColor: isSelectedTab ? palette.common.white : '#ffffff30',
                                          }
                                        : {
                                              color: isSelectedTab ? palette.common.black : 'unset',
                                              backgroundColor: isSelectedTab ? '#F4F4F5' : 'unset',
                                              border: '2px solid #F4F4F5',
                                          }),
                                    fontWeight: isSelectedTab ? 700 : 400,
                                    minWidth: '32px',
                                    px: '8px',
                                    borderRadius: '16px',
                                })}
                            >
                                {tasks.length}
                            </Box>
                        }
                        iconPosition="end"
                        sx={({ palette }) => ({
                            ...(isMobile ? { flex: 1 } : {}),
                            fontSize: '14px',
                            textWrap: 'nowrap',
                            fontWeight: 700,
                            color: { xs: palette.common.white, md: palette.common.black },
                        })}
                    />
                );
            })}
        </Tabs>
    );
};
