import { Avatar, styled } from '@mui/material';
import React from 'react';

const MUIAvatar = styled(Avatar)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    width: '40px',
    height: '40px',
}));

export default function ResidentAvatar({ alt, src }: { alt: string; src: string }) {
    return <MUIAvatar alt={alt} src={src} />;
}
