import { useAtom } from 'jotai';
import { useCallback, useMemo } from 'react';

import { alertsAtom } from './testData';
import { ECall } from './types.d';

export const useAlerts = () => {
    const [alerts, setAlerts] = useAtom(alertsAtom);

    const updateAlert = useCallback(
        (id: number, alert: ECall.Alert) => {
            setAlerts((prev) => {
                return prev.map((a) => (a.id === id ? alert : a));
            });
        },
        [setAlerts]
    );

    const claimAlert = useCallback(
        (id: number, caregiver: ECall.Caregiver) => {
            setAlerts((prev) => {
                return prev.map((a) =>
                    a.id === id ? { ...a, status: ECall.AlertStatus.InProgress, claimedCaregiver: caregiver } : a
                );
            });
        },
        [setAlerts]
    );

    return useMemo(
        () => ({
            alerts,
            updateAlert,
            claimAlert,
        }),
        [alerts, updateAlert, claimAlert]
    );
};
