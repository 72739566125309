import { useCallback } from 'react';

import { api } from '~/api';
import { useToken } from '~/lib/common';
import { useErrorHandledQuery } from '~/scheduling/api/common';

import { PrnTaskCategories } from '../types/prnTaskCategories';

// Queries the list unscheduled task categories by branch id
export const useCategoryQuery = (params: PrnTaskCategories.RequestParams) => {
    const { branchId } = params;
    const token = useToken();

    const queryFn = useCallback(async () => {
        const options = { headers: { Authorization: token } };
        const { data } = await api.get<PrnTaskCategories.Response>(
            `/prn-tasks/options/categories/?branch_id=${branchId}`,
            options
        );

        return data.response;
    }, [branchId, token]);

    return useErrorHandledQuery({ queryKey: ['unscheduled-task-categories', branchId], queryFn, staleTime: 30000 });
};
