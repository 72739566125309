import { Add as AddIcon } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { cloneDeep, isEmpty, orderBy } from 'lodash';
import moment, { Moment } from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useSound from 'use-sound';

import { getNearbyShiftsAtDateTimeUtc, getShiftAtDateTimeUtc } from '@allie/utils/src/shifts';

import { useBranchShifts, withBranchShiftSelector } from '~/api/queries/branch';
import { useCompanyCarePlansListQuery } from '~/api/queries/companyCarePlans';
import { useDailyTasks, useUpdateDailyTasks } from '~/api/queries/tasks/dailyTasks';
import notificationSound from '~/assets/notification-sound.mp3';
import { CustomTabPanel as TabPanel } from '~/components/Custom';
import CustomConfetti from '~/components/Custom/CustomConfetti';
import WithHeader from '~/components/Layout/WithHeader';
import { showToast } from '~/components/Shared/Alerting/Toast/utils/showToast';
import Loading from '~/components/Shared/Loading';
import SortDialog from '~/components/Shared/Sorting/SortDialog';
import { pxToRem } from '~/components/theme/typography';
import { ALL_ASSIGNMENTS_ID } from '~/constants/filters';
import { HOME_FILTERS } from '~/constants/localStorage';
import { getDateInUtc } from '~/lib/date';
import CheckTaskDialog from '~/pages/Home/components/CheckTaskDialog';
import TaskNotesDialog from '~/pages/Home/components/TaskNotesDialog';
import UnscheduledTaskDialog from '~/pages/Home/components/UnscheduledTaskDialog';
import PageStructure from '~/pages/PageStructure';
import { usePermissions } from '~/permissions/utils';
import { setSortBy, setSortOrder } from '~/redux/actions/filters';
import { hideAlert } from '~/redux/actions/messages';
import { toggleResidentParty } from '~/redux/actions/residents';
import { selectedAssignmentIdAtom } from '~/state/filteringAtoms';
import {
    CheckTaskMode,
    DailyTasksByTabStrict,
    DailyTasksShiftDetail,
    ResidentTasks,
    TabKey,
    TaskToUpdate,
} from '~/types/dailyTasks.d';
import { AppDispatch, ReduxStore } from '~/types/redux';

import { Header } from './components/Header';
import NewUnscheduledTaskDialog from './components/NewUnscheduledTaskDialog';
import useNewPrnFlow from './components/NewUnscheduledTaskDialog/hooks/useNewPrnFlow';
import { ResidentRows as ConfirmedResidentRows } from './confirmedTaskListComponents/ResidentRows';
import { LiveAlerts } from './eCallComponents';
import { ECall } from './eCallComponents/types.d';
import { useAlerts } from './eCallComponents/useAlerts';
import {
    ALL_ZONES_ID,
    NO_RESIDENT_SELECTED_ID,
    filterTasksByName,
    groupTasksByResidentId,
    shouldUseAssignments,
    taskRecordSorter,
} from './helpers';
import { ResidentRows as PendingResidentRows } from './taskListComponents/ResidentRows';

const TasksContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        position: 'relative',
        width: '100%',
    })
);

const AddButtonStyle = styled(Button)(({ theme }) =>
    theme.unstable_sx({
        mb: 'env(safe-area-inset-bottom)',
        p: 0,
        borderRadius: '50%',
        minWidth: 'unset',
        width: pxToRem(72),
        height: pxToRem(72),
        position: 'fixed',
        right: { xs: pxToRem(12), sm: pxToRem(24) },
        bottom: { xs: pxToRem(80), sm: pxToRem(24) },
        border: '2px solid #fff',
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
        '& svg': {
            fontSize: `${pxToRem(32)} !important`,
        },
    })
);

interface PropsFromRedux {
    timezone: string;
    zoneId?: number;
    sortBy?: string;
    sortOrder?: string;
    displayParty: boolean;
}

interface HomeProps extends PropsFromRedux {
    selectedBranchId: number | undefined;
    setSelectedBranchId: (branchId: number) => void;
    dispatchHideAlert: () => void;
    dispatchSetSortBy: (sortBy: string) => void;
    dispatchSetSortOrder: (sortOrder: string) => void;
    dispatchToggleResidentParty: () => void;
}

const Home = ({
    timezone,
    zoneId,
    sortBy,
    sortOrder,
    displayParty,
    selectedBranchId,
    setSelectedBranchId,
    dispatchHideAlert,
    dispatchSetSortBy,
    dispatchSetSortOrder,
    dispatchToggleResidentParty,
}: HomeProps) => {
    const { search } = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(search), [search]);
    const { alerts } = useAlerts();

    const { company, branch, branchId } = useSelector((state: ReduxStore) => state.session.sessionData);

    const shifts = useBranchShifts(selectedBranchId);
    const { mutateAsync: updateTaskMutation } = useUpdateDailyTasks();

    const {
        shift: { id: currentShiftId },
        shiftDayDate: currentShiftDayDate,
    } = getShiftAtDateTimeUtc(getDateInUtc(new Date()), timezone, shifts);

    const nearbyShifts = getNearbyShiftsAtDateTimeUtc(getDateInUtc(new Date()), timezone, shifts);

    const currentShiftDateTzMoment = moment(currentShiftDayDate);

    const [datepickerDate, setDatepickerDate] = useState<Moment>(currentShiftDateTzMoment);
    const [shift, setShift] = useState<number | null>(currentShiftId as number);
    // only select shift if it is a valid shift
    const isSelectedShiftValid =
        nearbyShifts.find(({ shift: { id: nearbyShiftId } }) => nearbyShiftId === shift) !== undefined;
    const selectedShiftId = shift && isSelectedShiftValid ? shift : (currentShiftId as number);

    // get shift id from query params initially if set to support linking documentation rate from email
    useEffect(() => {
        const selectedShiftId = queryParams.get('selected_shift_id');

        if (selectedShiftId && !isNaN(Number(selectedShiftId))) {
            setShift(Number(selectedShiftId));
        }
    }, [queryParams.get('selected_shift_id')]);

    // get shift day date from query params initially if set to support linking documentation rate from email
    useEffect(() => {
        const selectedShiftDate = queryParams.get('selected_shift_date');

        const momentDate = moment(selectedShiftDate);

        if (selectedShiftDate && momentDate.isValid()) {
            setDatepickerDate(momentDate);
        }
    }, [queryParams.get('selected_shift_date')]);

    const [selectedAssignmentId, setSelectedAssignmentId] = useAtom(selectedAssignmentIdAtom);
    const resetSelectedAssignmentId = useResetAtom(selectedAssignmentIdAtom);

    const [selectedTab, setSelectedTab] = useState<TabKey>(TabKey.LIVE_ALERTS);
    const [isAddedTaskDialogOpen, setIsAddedTaskDialogOpen] = useState<boolean>(false);
    const [isCheckTaskDialogOpen, setIsCheckTaskDialogOpen] = useState<boolean>(false);
    const [checkTaskDialogType] = useState<CheckTaskMode>('Complete');
    const [isTaskNotesDialogOpen, setIsTaskNotesDialogOpen] = useState<boolean>(false);
    const [selectedTaskNotes, setSelectedTaskNotes] = useState<string>();
    const [selectedResidentId, setSelectedResidentId] = useState<number>(NO_RESIDENT_SELECTED_ID);
    const [playCoinSound] = useSound(notificationSound);
    const [addedTaskDefaultResidentId, setAddedTaskDefaultResidentId] = useState<number>();
    const [isSortDialogOpen, setIsSortDialogOpen] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>('');

    const {
        isEnabled: isNewPrnFlowEnabled,
        isUnscheduledTaskDialogOpen: showNewUnscheduledTaskDialog,
        setIsUnscheduledTaskDialogOpen: setShowNewUnscheduledTaskDialog,
    } = useNewPrnFlow();

    const hasPermission = usePermissions();

    const { data: dailyTasksList, isLoading: dailyTasksListIsLoading } = useDailyTasks({
        branchId: selectedBranchId,
        date: datepickerDate.format('YYYY-MM-DD'),
    });

    const { data: carePlanData } = useCompanyCarePlansListQuery(branchId!);

    const carePlanIdToKeyService = useMemo(() => {
        const entries = carePlanData?.map(({ companyCarePlanId: id, keyService }) => [id, keyService]) ?? [];
        return Object.fromEntries(entries) as Record<number, string | undefined>;
    }, [carePlanData]);

    const permissionError = () => {
        showToast({
            message: 'Sorry, but this feature is exclusive for Directors/Caregivers',
            type: 'error',
        });
    };

    const handleFilterChange = (filter: string) => (newValue: number) => {
        if (filter === 'assignmentId') setSelectedAssignmentId(newValue);
        else resetSelectedAssignmentId();

        if (filter === 'branch') {
            setSelectedBranchId(newValue);

            // Reset the shift id to use the current shift
            setShift(null);
        } else if (filter === 'shift') {
            setShift(newValue);
        } else if (filter === 'resident') {
            setSelectedResidentId(newValue);
        } else {
            // when zone/assignment filter is changed unselect the currently selected resident
            setSelectedResidentId(NO_RESIDENT_SELECTED_ID);
        }
    };

    const handleAddTaskSubmit = () => {
        dispatchToggleResidentParty();
        playCoinSound();
    };

    const handleAddedTaskDialogOpen = () => {
        // If the user is not a Caregiver, throw an error and exit the function.
        if (!hasPermission('Community', 'undertake-resident-action')) {
            permissionError();
            return;
        }

        if (isNewPrnFlowEnabled) {
            setShowNewUnscheduledTaskDialog(true);
            return;
        }

        setIsAddedTaskDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsAddedTaskDialogOpen(false);
        setIsCheckTaskDialogOpen(false);
        setIsTaskNotesDialogOpen(false);
        setSelectedTaskNotes('');
    };

    const handleCompleteTaskClick = async ({ task }: { task: TaskToUpdate }) => {
        // If the user is not a allowed to perform this action, exit the function.
        if (!hasPermission('Community', 'update-resident-action')) {
            return;
        }

        // Trigger the action for updating the Task as Complete.
        await updateTaskMutation({ tasks: [task], branchId: Number(selectedBranchId) });

        if (hasPermission('Community', 'update-reward') && task.taskStatusId === 2) {
            // Trigger the sound for earning points.
            playCoinSound();
        }
    };

    const handleSortOptions = (selectedSortBy: string, selectedSortOrder: string) => {
        if (sortBy !== selectedSortBy) {
            dispatchSetSortBy(selectedSortBy);
        }
        if (sortOrder !== selectedSortOrder) {
            dispatchSetSortOrder(selectedSortOrder);
        }
    };

    const hasTaskWithKeyService = (tasks: DailyTasksShiftDetail[], keyService: string): boolean =>
        tasks.some(({ companyCarePlanId }) => carePlanIdToKeyService[companyCarePlanId] === keyService);

    const sortByShowerAndLaundry = (residentsTasksList: ResidentTasks[], isAscending: boolean): ResidentTasks[] => {
        const sortedArray = orderBy(
            residentsTasksList,
            [
                (item) => hasTaskWithKeyService(item.tasks, 'SHOWERING'),
                (item) => hasTaskWithKeyService(item.tasks, 'LAUNDRY'),
            ],
            [isAscending ? 'desc' : 'asc', isAscending ? 'desc' : 'asc']
        );

        return sortedArray;
    };

    const handleSort = (
        residentsTasksList: ResidentTasks[],
        selectedSortBy: keyof ResidentTasks | 'shower&Laundry',
        isAscending: boolean
    ): ResidentTasks[] => {
        if (selectedSortBy === 'shower&Laundry') {
            return sortByShowerAndLaundry(residentsTasksList, isAscending);
        }

        if (selectedSortBy === 'roomNumber') {
            return orderBy(residentsTasksList, (item) => Number(item[selectedSortBy]), isAscending ? 'asc' : 'desc');
        }

        return orderBy(residentsTasksList, selectedSortBy, isAscending ? 'asc' : 'desc');
    };

    useEffect(() => {
        // This check is necessary to ensure the correct initial tab will be selected
        if (!hasPermission('Community', 'ecall-caregiver') && selectedTab === TabKey.LIVE_ALERTS) {
            setSelectedTab(TabKey.PENDING);
        }
    }, []);

    const tasksByTab = useMemo<DailyTasksByTabStrict>(() => {
        if (!selectedShiftId) {
            return [];
        }

        const newTasksToShow: DailyTasksShiftDetail[] =
            dailyTasksList && dailyTasksList[selectedShiftId]?.length
                ? cloneDeep(dailyTasksList[selectedShiftId])
                      .filter((task) =>
                          shouldUseAssignments(company, branch)
                              ? selectedAssignmentId === ALL_ASSIGNMENTS_ID ||
                                task.assignmentId === selectedAssignmentId
                              : zoneId === ALL_ZONES_ID || task.zoneId === zoneId
                      )
                      .filter((task) => {
                          if (selectedResidentId === NO_RESIDENT_SELECTED_ID) {
                              return true;
                          }

                          return task.resident.id === selectedResidentId;
                      })
                : [];

        const pendingTasks = newTasksToShow.filter((task) => task.taskStatus === 'Undocumented');
        const completedTasks = newTasksToShow.filter((task) => task.taskStatus !== 'Undocumented');
        pendingTasks.sort(taskRecordSorter);
        completedTasks.sort(taskRecordSorter);

        const tabs: DailyTasksByTabStrict = [
            {
                key: 1,
                tabName: 'pending',
                tasks: pendingTasks,
            },
            {
                key: 2,
                tabName: 'completed',
                tasks: completedTasks,
            },
        ];

        if (hasPermission('Community', 'ecall-caregiver')) {
            tabs.unshift({
                key: 0,
                tabName: 'alerts',
                // TODO - use the correct data type when the backend is integrated
                tasks: alerts.filter(
                    (a) => a.status === ECall.AlertStatus.Unclaimed
                ) as unknown[] as DailyTasksShiftDetail[],
            });
        }

        return tabs;
    }, [selectedShiftId, selectedAssignmentId, zoneId, JSON.stringify(dailyTasksList), selectedResidentId, alerts]);

    useEffect(() => {
        if (selectedShiftId) {
            // Update the filters into the localStorage.
            const newFilters = {
                date: datepickerDate.format('YYYY-MM-DD HH:mm:ss'),
                shift: selectedShiftId,
            };
            localStorage.setItem(HOME_FILTERS, JSON.stringify(newFilters));
        }
    }, [datepickerDate.format('YYYY-MM-DD'), selectedShiftId]);

    useEffect(() => {
        if (!dailyTasksListIsLoading) {
            dispatchHideAlert();
        }
    }, [dailyTasksListIsLoading]);

    // HACK ASCENT: not showing loading so the page doesn't scroll to the top again immediately
    if (!currentShiftDateTzMoment || (hasPermission('Community', 'undertake-resident-action') && !selectedBranchId)) {
        return <Loading />;
    }

    return (
        <WithHeader
            header={
                <Header
                    tasksByTab={tasksByTab}
                    currentShiftDayDate={currentShiftDayDate}
                    currentShiftId={currentShiftId as number}
                    dailyTasksList={dailyTasksList}
                    selectedDate={datepickerDate}
                    selectedShiftId={selectedShiftId}
                    selectedBranchId={selectedBranchId}
                    selectedTab={selectedTab}
                    searchValue={searchValue}
                    shiftOptions={nearbyShifts}
                    onFilterChange={handleFilterChange}
                    onSearchValueChange={setSearchValue}
                    onDateChange={setDatepickerDate}
                    onTabChange={setSelectedTab}
                    onSortButtonClick={() => setIsSortDialogOpen(true)}
                />
            }
        >
            <PageStructure sx={{ padding: '0 !important' }}>
                {isEmpty(tasksByTab) ? (
                    <Typography variant="subtitle1">
                        There are no Tasks for the Date, Shift and Floor selected
                    </Typography>
                ) : (
                    <Box>
                        <TasksContainer>
                            {!!branchId &&
                                tasksByTab.map(({ key, tasks }) => {
                                    const filteredTasks = filterTasksByName({
                                        zoneId: zoneId ?? ALL_ZONES_ID,
                                        tasks,
                                        searchValue,
                                        assignmentId: selectedAssignmentId,
                                        filterBy: shouldUseAssignments(company, branch) ? 'assignmentId' : 'zoneId',
                                    });

                                    const residentsTasks = groupTasksByResidentId(filteredTasks);

                                    const residentsTasksList = Object.values(residentsTasks);

                                    const defaultResidentsTasksList = orderBy(
                                        residentsTasksList,
                                        (item) => Number(item.roomNumber),
                                        sortOrder === 'asc' ? 'asc' : 'desc'
                                    );

                                    const residentsTasksListSorted = handleSort(
                                        defaultResidentsTasksList,
                                        sortBy as keyof ResidentTasks | 'shower&Laundry',
                                        sortOrder === 'asc'
                                    );

                                    return (
                                        <TabPanel
                                            key={key}
                                            value={selectedTab}
                                            index={key}
                                            TabPanelProps={{ padding: 0 }}
                                        >
                                            {selectedTab === TabKey.LIVE_ALERTS && <LiveAlerts />}
                                            <Box sx={{ paddingInline: { xs: '18px', lg: '26px' } }}>
                                                {selectedTab === TabKey.PENDING && (
                                                    <PendingResidentRows
                                                        residentTasksList={residentsTasksListSorted}
                                                        date={datepickerDate.format('YYYY-MM-DD')}
                                                        shift={selectedShiftId}
                                                        branchId={branchId}
                                                        setSelectedResidentId={setAddedTaskDefaultResidentId}
                                                    />
                                                )}
                                                {selectedTab === TabKey.COMPLETED && (
                                                    <ConfirmedResidentRows
                                                        residentTasksList={residentsTasksListSorted}
                                                        branchId={branchId}
                                                    />
                                                )}
                                            </Box>
                                        </TabPanel>
                                    );
                                })}
                        </TasksContainer>
                    </Box>
                )}
                {hasPermission('Community', 'undertake-resident-action') && (
                    <AddButtonStyle variant="contained" color="primary" onClick={handleAddedTaskDialogOpen}>
                        <AddIcon />
                    </AddButtonStyle>
                )}
                <UnscheduledTaskDialog
                    isOpen={isAddedTaskDialogOpen}
                    defaultResidentId={addedTaskDefaultResidentId}
                    timezone={timezone}
                    currentShift={selectedShiftId}
                    onSubmit={handleAddTaskSubmit}
                    onClose={handleCloseDialog}
                    canChangeResident
                />
                <NewUnscheduledTaskDialog
                    isOpen={showNewUnscheduledTaskDialog}
                    onClose={() => setShowNewUnscheduledTaskDialog(false)}
                />
                <CheckTaskDialog
                    isOpen={isCheckTaskDialogOpen}
                    dialogType={checkTaskDialogType}
                    onSubmit={handleCompleteTaskClick}
                    onClose={handleCloseDialog}
                />
                <TaskNotesDialog
                    isOpen={isTaskNotesDialogOpen}
                    taskNote={selectedTaskNotes}
                    onClose={handleCloseDialog}
                />
                <SortDialog
                    isOpen={isSortDialogOpen}
                    onClose={() => setIsSortDialogOpen(false)}
                    onSort={handleSortOptions}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                />
                <CustomConfetti activate={displayParty} stop={dispatchToggleResidentParty} />
            </PageStructure>
        </WithHeader>
    );
};

const mapStateToProps = ({ session, filters, residents }: ReduxStore): PropsFromRedux => {
    const { timezone } = session;
    const { displayParty } = residents;
    const {
        caregiverApp: { zoneId, sortBy, sortOrder },
    } = filters;

    return {
        timezone,
        zoneId,
        sortBy,
        sortOrder,
        displayParty,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    dispatchHideAlert: () => dispatch(hideAlert()),
    dispatchSetSortBy: (sortBy: string) => dispatch(setSortBy(sortBy)),
    dispatchSetSortOrder: (sortOrder: string) => dispatch(setSortOrder(sortOrder)),
    dispatchToggleResidentParty: () => dispatch(toggleResidentParty()),
});

// This cast is needed because all the props come from redux and we don't want to expose them to the parent
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withBranchShiftSelector(Home)) as unknown as () => React.ReactNode;
