import { api } from '~/api';
import { useErrorHandledQuery } from '~/hooks/useErrorHandledQuery';
import { formatApiParams, useToken } from '~/lib/common';
import {
    OperationsBranchStatsParams,
    OperationsBranchStatsResponse,
    OperationsOverallStatsParams,
    OperationsOverallStatsResponse,
} from '~/types/operationsDashboard';

export const useOperationsOverallStatsQuery = (jsonParams?: OperationsOverallStatsParams) => {
    const params = jsonParams ? formatApiParams(jsonParams) : undefined;
    const token = useToken();

    return useErrorHandledQuery<OperationsOverallStatsResponse>(
        ['operations-overall-stats', jsonParams],
        async (): Promise<OperationsOverallStatsResponse> => {
            const { data } = await api.get<{ response: OperationsOverallStatsResponse }>(
                '/analytics/operations/overall-stats',
                {
                    headers: { authorization: token },
                    params,
                }
            );
            return data.response;
        },
        { enabled: !!jsonParams, staleTime: 300000 }
    );
};

export const useOperationsBranchStatsQuery = (jsonParams?: OperationsBranchStatsParams) => {
    const params = jsonParams ? formatApiParams(jsonParams) : undefined;
    const token = useToken();

    return useErrorHandledQuery<OperationsBranchStatsResponse>(
        ['operations-community-stats', jsonParams],
        async () => {
            const { data } = await api.get<{ response: OperationsBranchStatsResponse }>(
                '/analytics/operations/branch-stats',
                {
                    headers: { authorization: token },
                    params,
                }
            );
            return data.response;
        },
        { enabled: !!jsonParams, staleTime: 300000 }
    );
};
