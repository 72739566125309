import { Box, Typography, styled } from '@mui/material';
import React from 'react';

type Props = {
    icon: React.ReactNode;
    label: string;
    onClick: () => void;
};

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.grey[100]}`,
    alignItems: 'center',
    justifyContent: 'center',
    padding: '12px 16px',
    gap: '8px',
    borderRadius: '8px',
    aspectRatio: '1 / 1',
    width: '100%',
    minWidth: 0,
    boxSizing: 'border-box',
    cursor: 'pointer',
}));

const Label = styled(Typography)(() => ({
    wordBreak: 'break-word',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: 600,
}));

export default function SquareIconButton({ icon, label, onClick }: Readonly<Props>) {
    return (
        <Container onClick={onClick}>
            <Box flexGrow={1} display="flex" alignItems="center" justifyContent="center">
                {icon}
            </Box>
            <Label noWrap={false} sx={{ flexShrink: 1, overflow: 'hidden' }}>
                {label}
            </Label>
        </Container>
    );
}
