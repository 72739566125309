import { Box, Button, Typography, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';

import { useTimer } from '~/hooks/useTimer';

import { ECall } from '../types.d';

interface TipProps {
    step: number;
    label: string | React.ReactNode;
}

const Step = ({ step, label }: TipProps) => {
    const { palette } = useTheme();

    return (
        <Box display="flex" gap="8px">
            <Box
                display="flex"
                minWidth="24px"
                height="24px"
                bgcolor={palette.secondary[300] as string}
                borderRadius="12px"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="body1" color={palette.grey[900]} fontWeight={600} fontSize="12px">
                    {step}
                </Typography>
            </Box>
            <Typography variant="body1" color={palette.grey[900]} fontWeight={500} fontSize="16px">
                {label}
            </Typography>
        </Box>
    );
};

interface InProgressAlertDetailsProps {
    requestedAt: DateTime;
    roomNumber: string;
    suggestedLocations?: string[];
    currentShift: string;
    shiftTasks: string[];
}

const InProgressAlertDetails = ({
    requestedAt,
    roomNumber,
    suggestedLocations,
    currentShift,
    shiftTasks,
}: InProgressAlertDetailsProps) => {
    const { palette } = useTheme();
    const timer = useTimer(requestedAt);

    return (
        <>
            <Box padding="16px">
                <Step step={1} label={`Go to Room ${roomNumber}`} />

                {suggestedLocations && (
                    <Box
                        marginTop="8px"
                        display="flex"
                        flexDirection="column"
                        bgcolor="white"
                        padding="16px"
                        borderRadius="8px"
                    >
                        <Typography variant="body1" color={palette.grey[600]} fontWeight={500}>
                            Resident not there? Try these locations:
                        </Typography>

                        <Typography variant="body1" color={palette.grey[600]} fontWeight={400}>
                            {suggestedLocations.join(', ')}
                        </Typography>
                    </Box>
                )}

                <Box width="100%" height="16px" />

                <Step step={2} label="Double tap Resident's button to stop the timer" />

                <Box
                    marginTop="16px"
                    padding="16px 24px"
                    borderRadius="8px"
                    bgcolor={palette.warning[200] as string}
                    border={`2px solid ${palette.warning[500]}`}
                >
                    <Typography variant="body1" color={palette.grey[900]} fontWeight={400}>
                        Resident has been waiting for <span style={{ fontWeight: 700, fontSize: '16px' }}>{timer}</span>
                    </Typography>
                </Box>
            </Box>

            <Box bgcolor={palette.grey[50]} padding="16px">
                <Typography variant="body1" color={palette.grey[600]} fontWeight={500}>
                    {currentShift} Routine
                </Typography>
                <ul style={{ paddingLeft: '16px' }}>
                    {shiftTasks?.map((task, index) => (
                        <li key={index}>
                            <Typography variant="body1" color={palette.grey[600]} fontWeight={400}>
                                {task}
                            </Typography>
                        </li>
                    ))}
                </ul>
            </Box>
        </>
    );
};

export const ResidentReachedAlertDetails = ({ startedAt }: { startedAt: DateTime }) => {
    const { palette } = useTheme();
    const timer = useTimer(startedAt);

    return (
        <>
            <Step
                step={1}
                label={
                    <>
                        Click <span style={{ fontWeight: 700 }}>Done</span> to complete request
                    </>
                }
            />
            <Box
                marginBlock="16px"
                padding="16px 24px"
                borderRadius="8px"
                bgcolor={palette.warning[200] as string}
                border={`2px solid ${palette.warning[500]}`}
            >
                <Typography variant="body1" color={palette.grey[900]} fontWeight={400} lineHeight="16px">
                    You have been in progress for <span style={{ fontWeight: 700, fontSize: '16px' }}>{timer}</span>
                </Typography>
            </Box>

            <Button fullWidth variant="contained">
                Done
            </Button>
        </>
    );
};

interface AlertDetailsProps {
    requestedAt: DateTime;
    roomNumber: string;
    status: ECall.AlertStatus;
    claimedByUser: boolean;
    suggestedLocations?: string[];
    shiftTasks: string[];
    currentShift: string;
    startedAt?: DateTime;
}

export const AlertDetails = ({
    roomNumber,
    suggestedLocations,
    requestedAt,
    status,
    claimedByUser,
    currentShift,
    shiftTasks,
    startedAt,
}: AlertDetailsProps) => {
    const { palette } = useTheme();

    if (!claimedByUser) {
        // TODO - coming soon...
        // https://www.figma.com/design/steN7hxfo8GPWTHReyceT0/E-Call?node-id=737-21201&node-type=frame&m=dev
        return <></>;
    }

    return (
        <Box
            margin="16px"
            padding="16px"
            bgcolor={palette.grey[25]}
            border={`1px solid ${palette.grey[200]}`}
            borderRadius="8px"
        >
            {status === ECall.AlertStatus.InProgress && (
                <InProgressAlertDetails
                    roomNumber={roomNumber}
                    requestedAt={requestedAt}
                    suggestedLocations={suggestedLocations}
                    currentShift={currentShift}
                    shiftTasks={shiftTasks}
                />
            )}
            {status === ECall.AlertStatus.ResidentReached && <ResidentReachedAlertDetails startedAt={startedAt!} />}
        </Box>
    );
};
