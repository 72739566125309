import { DateTime } from 'luxon';

// TODO - use backend types when they exists
export namespace ECall {
    export type Caregiver = {
        id: number;
        firstName: string;
        lastName: string;
    };

    export type Resident = {
        id: number;
        firstName: string;
        lastName: string;
        roomNumber: string;
        photo: string;
    };

    export enum UnclaimedLevel {
        Level1,
        Level2,
        Level3,
    }

    export enum AlertStatus {
        Unclaimed,
        InProgress,
        ResidentReached,
        Claimed,
    }

    export type ShiftTasks = {
        shiftName: string;
        tasks: string[];
    };

    export type Alert = {
        id: number;
        requestedAt: DateTime;
        startedAt?: DateTime;
        claimedCaregiver?: Caregiver | null;
        resident: Resident;
        status: AlertStatus;
        unclaimedLevel: UnclaimedLevel;
        suggestedLocations?: string[];
        currentShiftTasks: ShiftTasks;
    };
}
