import { atom } from 'jotai';

import { UnscheduledTaskDialogSteps } from '~/pages/Home/components/NewUnscheduledTaskDialog/types/unscheduledTaskDialogSteps';
import { ResidentsListResponse } from '~/types/residents';

import { PrnTaskActionsTaken } from '../steps/actionsTaken/types/prnTaskActionsTaken';
import { PrnTaskAssistLevel } from '../steps/assistLevel/types/prnTaskAssistLevel';
import { PrnTaskCategories } from '../steps/category/types/prnTaskCategories';
import { PrnTaskSubcategories } from '../steps/subcategory/types/prnTaskSubcategories';

const unscheduledTaskModalOpenAtom = atom(false);

const unscheduledTaskStepAtom = atom<UnscheduledTaskDialogSteps>(UnscheduledTaskDialogSteps.SELECT_RESIDENT);

const unscheduledTaskShiftIdAtom = atom<number | null>(null);

const unscheduledTaskResidentAtom = atom<ResidentsListResponse | null>(null);

const unscheduledTaskCategoryAtom = atom<PrnTaskCategories.Category | null>(null);

const unscheduledTaskSubcategoryAtom = atom<PrnTaskSubcategories.Subcategory | null>(null);

const unscheduledTaskActionsTakenAtom = atom<PrnTaskActionsTaken.ActionTaken[] | null>(null);

const unscheduledTaskActionsTakenNotesAtom = atom<string | null>(null);

const unscheduledTaskIsMissingRequiredCommentAtom = atom(false);

const unscheduledTaskAssistLevelAtom = atom<PrnTaskAssistLevel.AssistLevel | null>(null);

export {
    unscheduledTaskActionsTakenAtom,
    unscheduledTaskActionsTakenNotesAtom,
    unscheduledTaskAssistLevelAtom,
    unscheduledTaskCategoryAtom,
    unscheduledTaskModalOpenAtom,
    unscheduledTaskResidentAtom,
    unscheduledTaskShiftIdAtom,
    unscheduledTaskStepAtom,
    unscheduledTaskSubcategoryAtom,
    unscheduledTaskIsMissingRequiredCommentAtom,
};
