import { useAtom } from 'jotai';
import { useSelector } from 'react-redux';

import { ReduxStore } from '~/types/redux';

import { unscheduledTaskCategoryAtom, unscheduledTaskSubcategoryAtom } from '../../../state/atom';

import { useSubcategoryQuery } from './useSubcategoryQuery';

export default function useSelectSubcategoryStep() {
    const { branchId } = useSelector((state: ReduxStore) => state.session.sessionData);
    const [selectedCategory] = useAtom(unscheduledTaskCategoryAtom);
    const [selectedSubcategory, setSelectedSubcategory] = useAtom(unscheduledTaskSubcategoryAtom);

    const {
        data: prnTaskSubcategories,
        isLoading,
        isError,
    } = useSubcategoryQuery({
        branchId: branchId!,
        prnCategoryId: selectedCategory?.id ?? 1,
    });

    return {
        prnTaskSubcategories,
        isLoading,
        isError,
        selectedSubcategory,
        setSelectedSubcategory,
    };
}
