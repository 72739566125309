import { api } from '~/api';
import { useErrorHandledQuery } from '~/hooks/useErrorHandledQuery';
import { formatApiParams, useToken } from '~/lib/common';
import { BranchesByRegionParams, BranchesByRegionResponse } from '~/types/companyInfo';

import { usePermissionsQuery } from './permissions';

export const useBranchesByRegionQuery = (jsonParams: BranchesByRegionParams, enabled = true) => {
    const { isSuccess: isPermitLoaded } = usePermissionsQuery();

    const token = useToken();

    const params = formatApiParams(jsonParams);
    Object.keys(params).forEach((key) => !Number.isInteger(params[key]) && delete params[key]); // Remove non-integer params

    return useErrorHandledQuery<BranchesByRegionResponse>(
        ['branches-by-region'],
        async (): Promise<BranchesByRegionResponse> => {
            const { data } = await api.get<{ response: BranchesByRegionResponse }>('/company-info/branches-by-region', {
                headers: { authorization: token },
                params,
            });

            return data.response;
        },
        {
            enabled: enabled && (!!jsonParams.companyId || !!jsonParams.regionId || !!jsonParams.branchId),
            staleTime: 300000,
        },
        !!isPermitLoaded
    );
};
