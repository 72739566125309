import { Box, Checkbox, Typography, styled } from '@mui/material';
import React from 'react';
import { BsCheck as CheckIcon } from 'react-icons/bs';

type Props = {
    label: string;
    selected: boolean;
    onClick: () => void;
};

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '12px',
    width: '100%',
    height: '45px',
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: '8px',
    cursor: 'pointer',
}));

const Label = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[600],
    fontSize: '14px',
    fontWeight: 600,
    textAlign: 'left',
    textWrap: 'balance',
}));

export default function ListItemCheckboxButton({ label, selected, onClick }: Readonly<Props>) {
    return (
        <Container
            onClick={onClick}
            sx={{
                backgroundColor: ({ palette }) => (selected ? palette.primary[50] : 'white'),
                border: ({ palette }) =>
                    selected ? `1px solid ${palette.primary[200]}` : `1px solid ${palette.grey[100]}`,
            }}
        >
            <Checkbox
                checked={selected}
                onChange={onClick}
                icon={
                    <Box
                        sx={{
                            width: '20px',
                            height: '20px',
                            borderRadius: '50%',
                            bgcolor: 'selector.deselected',
                            border: ({ palette }) => `1px solid ${palette.grey[300]}`,
                        }}
                    />
                }
                checkedIcon={
                    <CheckIcon
                        fill="#F2F8F8"
                        style={{
                            width: '20px',
                            height: '20px',
                            backgroundColor: '#006b75',
                            borderRadius: '50%',
                        }}
                    />
                }
            />
            <Label>{label}</Label>
        </Container>
    );
}
