import { Box, TextField, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import React from 'react';

import ListItemCheckboxButton from '~/components/Shared/Button/ListItemCheckboxButton';

import PrnFlowStep from '../../components/PrnFlowStep';
import { unscheduledTaskSubcategoryAtom } from '../../state/atom';

import useSelectActionsTakenStep from './hooks/useSelectActionsTakenStep';
import { PrnTaskActionsTaken } from './types/prnTaskActionsTaken';

export default function SelectActionsTakenStep() {
    const {
        prnTaskActionsTaken,
        isLoading,
        isError,
        setSelectedActionsTaken,
        selectedActionsTaken,
        setActionsTakenNotes,
        actionsTakenNotes,
        isMissingRequiredComment,
        setIsMissingRequiredComment,
    } = useSelectActionsTakenStep();
    const selectedSubcategory = useAtomValue(unscheduledTaskSubcategoryAtom);
    const isCommentRequired = selectedActionsTaken?.some((action) => action.commentRequired) ?? false;

    const handleSelectActionsTaken = (actionTaken: PrnTaskActionsTaken.ActionTaken) => {
        setIsMissingRequiredComment(false);
        setSelectedActionsTaken((prevActions) => {
            const isAlreadySelected = prevActions?.some((action) => action.id === actionTaken.id);
            if (isAlreadySelected) {
                return prevActions?.filter((action) => action.id !== actionTaken.id) ?? [];
            } else {
                return [...(prevActions ?? []), actionTaken];
            }
        });
    };

    const handleAddActionsTakenNotes = (notes: string) => {
        setIsMissingRequiredComment(false);
        setActionsTakenNotes(notes);
    };

    return (
        <PrnFlowStep isLoading={isLoading} isError={isError}>
            <Typography variant="body1" fontWeight={700} mb="8px">
                {selectedSubcategory?.label ?? 'Actions Taken'} (Select all that apply)
            </Typography>

            <Box display="flex" flexDirection="column" gap="8px">
                {prnTaskActionsTaken?.map((actionTaken) => (
                    <ListItemCheckboxButton
                        key={actionTaken.id}
                        label={actionTaken.label}
                        selected={selectedActionsTaken?.some((action) => action.id === actionTaken.id) ?? false}
                        onClick={() => handleSelectActionsTaken(actionTaken)}
                    />
                ))}
            </Box>

            <Box mt="24px">
                <Typography variant="body1" fontWeight={700} mb="8px">
                    {`Add a comment ${!isCommentRequired ? '(optional)' : '(required)'}`}
                </Typography>
                <TextField
                    multiline
                    rows={4}
                    fullWidth
                    placeholder="Enter any additional notes about the actions taken."
                    value={actionsTakenNotes}
                    onChange={(e) => handleAddActionsTakenNotes(e.target.value)}
                    inputProps={{
                        style: {
                            padding: '8px',
                        },
                    }}
                    error={isMissingRequiredComment}
                    helperText={isMissingRequiredComment ? 'A comment is required for the selected action(s).' : ''}
                />
            </Box>
        </PrnFlowStep>
    );
}
