import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { Box, Button, CircularProgress, IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { captureException } from '@sentry/react';
import { VoiceRecorder } from 'capacitor-voice-recorder';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { isChrome } from 'react-device-detect';
import { connect, useSelector } from 'react-redux';

import { api } from '~/api';
import langDictionary from '~/app-strings';
import audioWave from '~/assets/audio-wave2.gif';
import { CustomTextField } from '~/components/Custom';
import { MicrophoneIcon } from '~/components/Svg';
import { pxToRem } from '~/components/theme/typography';
import { useToken } from '~/lib/common';
import { handleError } from '~/redux/actions/messages';
import { readUser } from '~/redux/actions/users';
import { ReduxStore } from '~/types/redux';
import { UserResponse } from '~/types/users';

import ActivateMicConfirmation from './ActivateMicConfirmation';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles((theme: any) => ({
    micIcon: {
        color: theme.palette.primary.main,
    },
}));

interface StopRecordingProps {
    discard?: boolean;
}

type Props = {
    onboardingId?: string;
    label?: string;
    placeholder: string;
    showActivateMicConfirmation: boolean;
    text: string;
    analyticsIdText: string;
    user: UserResponse;
    onChange: (text: string) => void;
    toggleShowActivateMicConfirmation: (show: boolean) => void;
    dispatchThrowError: (error: string) => void;
    dispatchReadUser: () => void;
    isRequired?: boolean;
};

const TranscriptionTextField = (props: Props) => {
    const {
        onboardingId,
        label,
        placeholder,
        showActivateMicConfirmation,
        text,
        analyticsIdText,
        user,
        onChange,
        toggleShowActivateMicConfirmation,
        dispatchThrowError,
        dispatchReadUser,
        isRequired,
    } = props;
    const { error: errorTxt } = langDictionary;

    const displayTranscriptionButton = Capacitor.isNativePlatform() || isChrome;

    const classes = useStyles();

    const userToken = useToken();

    const [recording, setRecording] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deviceLanguage, setDeviceLanguage] = useState<string>('en');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const { branchId } = useSelector((state: ReduxStore) => state.session.sessionData);

    useEffect(() => {
        if (errorMessage) {
            dispatchThrowError(errorMessage);
        }
    }, [errorMessage]);

    useEffect(() => {
        // If there is no data for the current user, then fetch it.
        if (isEmpty(user)) {
            // Fetch the information from the user.
            dispatchReadUser();
        }

        Device.getLanguageCode().then(({ value }) => {
            setDeviceLanguage(value);
        });
    }, []);

    // eslint-disable-next-line arrow-body-style
    useEffect(() => {
        //
        return () => {
            stopRecording({ discard: true });
        };
    }, []);

    useEffect(() => {
        if (recording) {
            // Discard recording after 2 minutes to avoid accidental long recordings
            const timer = setTimeout(() => {
                stopRecording({ discard: true });
            }, 120000);

            return () => clearTimeout(timer);
        }

        return undefined;
    }, [recording]);

    const checkPermission = async () => {
        const { value: hasPermission } = await VoiceRecorder.hasAudioRecordingPermission();

        if (hasPermission) {
            startRecording();
        } else {
            toggleShowActivateMicConfirmation(true);
        }
    };

    const requestPermission = async () => {
        const { value: grantedPermission } = await VoiceRecorder.requestAudioRecordingPermission();

        if (grantedPermission) {
            startRecording();
        } else {
            setErrorMessage(errorTxt.recordingPermission);
        }
    };

    const startRecording = () => {
        setRecording(true);
        VoiceRecorder.startRecording();
    };

    const stopRecording = async ({ discard = false }: StopRecordingProps) => {
        setRecording(false);

        const { status } = await VoiceRecorder.getCurrentStatus();
        if (status === 'NONE') {
            return;
        }

        const { recordDataBase64, mimeType } = (await VoiceRecorder.stopRecording()).value;

        if (discard) return;

        setLoading(true);

        // https://stackoverflow.com/a/36183085
        const blob = await fetch(`data:${mimeType};base64,${recordDataBase64}`).then((res) => res.blob());

        const form = new FormData();
        form.append('file', blob);
        form.append('language', 'en' || deviceLanguage); // TODO [AH-522]: support more than english using deviceLanguage

        try {
            const response = await api.post(`/resident-shift-notes/transcribe-audio?branch_id=${branchId}`, form, {
                headers: {
                    authorization: userToken,
                },
            });

            if (response.data.status !== 'OK') {
                setErrorMessage(errorTxt.transcription);
                setLoading(false);
                return;
            }

            const newText = text?.length > 0 ? `${text} ${response.data.response.text}` : response.data.response.text;

            onChange(newText);
            setErrorMessage(null);
        } catch (err) {
            captureException(err);
            setErrorMessage(errorTxt.transcription);
        }

        setLoading(false);
    };

    const renderRecordButton = () => {
        if (!displayTranscriptionButton) {
            return null;
        }

        if (loading) {
            return <CircularProgress color="inherit" size={20} />;
        }

        if (recording) {
            return (
                <Button
                    sx={{
                        fontWeight: 600,
                    }}
                    color="primary"
                    size="small"
                    onClick={() => {
                        stopRecording({ discard: false });
                    }}
                    data-analytics-id={`${analyticsIdText}-stop-recording`}
                >
                    <img src={audioWave} alt="transcribing" style={{ height: '20px', marginRight: '5px' }} />
                    End Recording
                </Button>
            );
        }

        return (
            <IconButton
                sx={{
                    color: '#6F6F79',
                    bgcolor: 'transparent',
                    '&:hover': {
                        bgcolor: 'transparent',
                    },
                }}
                onClick={() => {
                    checkPermission();
                }}
                data-analytics-id={`${analyticsIdText}-start-recording`}
            >
                <MicrophoneIcon className={classes.micIcon} />
            </IconButton>
        );
    };

    const handleAllowMicClick = () => {
        toggleShowActivateMicConfirmation(false);
        requestPermission();
    };

    if (showActivateMicConfirmation) {
        return <ActivateMicConfirmation showConfirmation={showActivateMicConfirmation} onAllow={handleAllowMicClick} />;
    }

    return (
        <Stack spacing={pxToRem(16)}>
            <Box sx={{ position: 'relative' }}>
                <CustomTextField
                    id={onboardingId}
                    label={label || ''}
                    placeholder={placeholder}
                    value={text}
                    fullWidth
                    multiline
                    rows={3}
                    onChange={(newText: string) => {
                        onChange(newText);
                    }}
                    customErrorMessage={isRequired && !text ? 'You need to provide a comment' : ''}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        right: pxToRem(4),
                        bottom: { xs: pxToRem(4), lg: pxToRem(4) },
                    }}
                >
                    {renderRecordButton()}
                </Box>
            </Box>
            {displayTranscriptionButton && (
                <Box
                    sx={{
                        bgcolor: (theme) => theme.palette.grey[50],
                        px: pxToRem(10),
                        py: pxToRem(6),
                        borderLeft: (theme) => `2px solid ${theme.palette.grey[100]}`,
                    }}
                >
                    <Typography
                        align="left"
                        sx={{
                            fontSize: `${pxToRem(11.5)} !important`,
                            lineHeight: 1.3,
                        }}
                    >
                        You can document your note by speaking to the phone in English!{' '}
                        <Box component="span" fontWeight="bold">
                            Click the green microphone button to start.
                        </Box>{' '}
                        Make sure to{' '}
                        <Box component="span" fontWeight="bold">
                            double check
                        </Box>{' '}
                        the text is correct before submitting.
                    </Typography>
                </Box>
            )}
        </Stack>
    );
};

const mapStateToProps = ({ users }: ReduxStore) => {
    const { user } = users;
    return {
        user,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchReadUser: () => dispatch(readUser()),
    dispatchThrowError: (error) =>
        dispatch(
            handleError({
                alertMessage: error,
                consoleMessage: error,
                error,
            })
        ),
});

export default connect(mapStateToProps, mapDispatchToProps)(TranscriptionTextField);
