import { Typography } from '@mui/material';
import React from 'react';

import ButtonGrid from '~/components/Shared/Button/ButtonGrid';
import SquareIconButton from '~/components/Shared/Button/SquareIconButton';

import PrnFlowStep from '../../components/PrnFlowStep';

import useSelectAssistLevelStep from './hooks/useSelectAssistLevelStep';
import { PrnTaskAssistLevel } from './types/prnTaskAssistLevel';

export default function SelectAssistLevelStep({ goToNextStep }: Readonly<{ goToNextStep: () => void }>) {
    const { prnTaskAssistLevels, isLoading, isError, setSelectedAssistLevel } = useSelectAssistLevelStep();

    const handleSelectAssistLevel = (assistLevel: PrnTaskAssistLevel.AssistLevel) => {
        setSelectedAssistLevel(assistLevel);
        goToNextStep();
    };

    return (
        <PrnFlowStep isLoading={isLoading} isError={isError}>
            <Typography variant="body1" fontWeight={700} mb="8px">
                Select Assist Level
            </Typography>

            <ButtonGrid maxItemsPerRow={3}>
                {prnTaskAssistLevels?.map((assistLevel) => (
                    <SquareIconButton
                        key={assistLevel?.id}
                        icon={<assistLevel.icon color={'black'} weight="bold" size={24} />}
                        label={assistLevel?.label}
                        onClick={() => handleSelectAssistLevel(assistLevel)}
                    />
                ))}
            </ButtonGrid>
        </PrnFlowStep>
    );
}
