import { useCallback } from 'react';

import { api } from '~/api';
import { useToken } from '~/lib/common';
import { useErrorHandledQuery } from '~/scheduling/api/common';

import { PrnTaskSubcategories } from '../types/prnTaskSubcategories';

// Queries the list unscheduled task subcategories by branch id and category id
export const useSubcategoryQuery = (params: PrnTaskSubcategories.RequestParams) => {
    const { branchId, prnCategoryId } = params;
    const token = useToken();

    const queryFn = useCallback(async () => {
        const options = { headers: { Authorization: token }, params: { branch_id: branchId, prnCategoryId } };
        const { data } = await api.get<PrnTaskSubcategories.Response>(`/prn-tasks/options/subcategories`, options);

        return data.response;
    }, [branchId, token]);

    return useErrorHandledQuery({
        queryKey: ['unscheduled-task-subcategories', branchId, prnCategoryId],
        queryFn,
        staleTime: 900000,
    });
};
