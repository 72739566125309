import { useAtom } from 'jotai';
import { useSelector } from 'react-redux';

import { ReduxStore } from '~/types/redux';

import {
    unscheduledTaskAssistLevelAtom,
    unscheduledTaskCategoryAtom,
    unscheduledTaskSubcategoryAtom,
} from '../../../state/atom';
import withIcon from '../utils/withIcon';

import { useAssistLevelQuery } from './useAssistLevelQuery';

export default function useSelectAssistLevelStep() {
    const { branchId } = useSelector((state: ReduxStore) => state.session.sessionData);
    const [selectedCategory] = useAtom(unscheduledTaskCategoryAtom);
    const [selectedSubcategory] = useAtom(unscheduledTaskSubcategoryAtom);
    const [selectedAssistLevel, setSelectedAssistLevel] = useAtom(unscheduledTaskAssistLevelAtom);

    const {
        data: prnTaskAssistLevels,
        isLoading,
        isError,
    } = useAssistLevelQuery({
        branchId: branchId!,
        prnCategoryId: selectedCategory?.id ?? 1,
        prnSubcategoryId: selectedSubcategory?.id,
    });

    return {
        prnTaskAssistLevels: prnTaskAssistLevels?.map(withIcon) ?? [],
        isLoading,
        isError,
        selectedAssistLevel,
        setSelectedAssistLevel,
    };
}
