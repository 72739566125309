import * as Sentry from '@sentry/react';
import { useAtom, useSetAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { showToast } from '~/components/Shared/Alerting/Toast/utils/showToast';
import { UnscheduledTaskDialogSteps } from '~/pages/Home/components/NewUnscheduledTaskDialog/types/unscheduledTaskDialogSteps';
import { ReduxStore } from '~/types/redux';

import {
    unscheduledTaskActionsTakenAtom,
    unscheduledTaskActionsTakenNotesAtom,
    unscheduledTaskAssistLevelAtom,
    unscheduledTaskCategoryAtom,
    unscheduledTaskIsMissingRequiredCommentAtom,
    unscheduledTaskModalOpenAtom,
    unscheduledTaskResidentAtom,
    unscheduledTaskShiftIdAtom,
    unscheduledTaskStepAtom,
    unscheduledTaskSubcategoryAtom,
} from '../state/atom';
import { useUnscheduledTaskSubmitRequest } from '../steps/submit/hooks/useUnscheduledTaskSubmitRequest';

export default function useUnscheduledTaskDialog() {
    const { branchId } = useSelector((state: ReduxStore) => state.session.sessionData);
    const [step, setStep] = useAtom(unscheduledTaskStepAtom);
    const [shiftId, setShiftId] = useAtom(unscheduledTaskShiftIdAtom);
    const [resident, setResident] = useAtom(unscheduledTaskResidentAtom);
    const [category, setCategory] = useAtom(unscheduledTaskCategoryAtom);
    const [subcategory, setSubcategory] = useAtom(unscheduledTaskSubcategoryAtom);
    const [assistLevel, setAssistLevel] = useAtom(unscheduledTaskAssistLevelAtom);
    const [actionsTaken, setActionsTaken] = useAtom(unscheduledTaskActionsTakenAtom);
    const [actionsTakenNotes, setActionsTakenNotes] = useAtom(unscheduledTaskActionsTakenNotesAtom);
    const setIsMissingRequiredComment = useSetAtom(unscheduledTaskIsMissingRequiredCommentAtom);
    const { mutateAsync: submitUnscheduledTask, isPending: isSubmitUnscheduledTaskPending } =
        useUnscheduledTaskSubmitRequest();
    const setIsUnscheduledTaskDialogOpen = useSetAtom(unscheduledTaskModalOpenAtom);

    const isValid = (): boolean => {
        if (resident === null || isSubmitUnscheduledTaskPending) {
            return false;
        }

        if (step === UnscheduledTaskDialogSteps.SELECT_RESIDENT) {
            return true;
        }

        if (step === UnscheduledTaskDialogSteps.SELECT_ACTIONS_TAKEN) {
            if (!actionsTaken || (actionsTaken && actionsTaken.length === 0)) {
                return false;
            }

            const requiresComment = actionsTaken.some((action) => action.commentRequired);
            if (requiresComment && actionsTakenNotes?.trim() === '') {
                setIsMissingRequiredComment(true);
                return false;
            }

            return true;
        }

        return false;
    };

    const formValues = useMemo(
        () => ({
            shiftId,
            residentId: resident?.residentId,
            prnCategoryId: category?.id,
            prnAssistLevelId: assistLevel?.id,
            prnSubcategoryId: subcategory?.id,
            prnActionsTaken: actionsTaken?.map((action) => action.id) ?? [],
            prnActionsTakenNotes: actionsTakenNotes,
        }),
        [shiftId, resident, category?.id, assistLevel?.id, subcategory?.id, actionsTaken, actionsTakenNotes]
    );

    const resetFlow = useCallback(() => {
        setStep(UnscheduledTaskDialogSteps.SELECT_RESIDENT);
        setResident(null);
        setCategory(null);
        setSubcategory(null);
        setAssistLevel(null);
        setActionsTaken([]);
        setActionsTakenNotes('');
        setIsMissingRequiredComment(false);
    }, [
        setStep,
        setResident,
        setCategory,
        setSubcategory,
        setAssistLevel,
        setActionsTaken,
        setActionsTakenNotes,
        setIsMissingRequiredComment,
    ]);

    const progress = useMemo(() => {
        if (step === UnscheduledTaskDialogSteps.SELECT_RESIDENT) return 20;
        if (step === UnscheduledTaskDialogSteps.SELECT_CATEGORY) return 40;
        if (step === UnscheduledTaskDialogSteps.SELECT_SUBCATEGORY) return 60;
        if (step === UnscheduledTaskDialogSteps.SELECT_ASSIST_LEVEL) return 80;
        if (step === UnscheduledTaskDialogSteps.SELECT_ACTIONS_TAKEN) return 100;

        return 0;
    }, [step]);

    const onSubmit = useCallback(async () => {
        try {
            if (!isValid()) {
                return;
            }

            await submitUnscheduledTask({
                branchId: branchId!,
                residentId: formValues.residentId!,
                prnCategoryId: formValues.prnCategoryId!,
                prnSubcategoryId: formValues.prnSubcategoryId,
                prnAssistLevelId: formValues.prnAssistLevelId,
                prnActionsTakenIds: formValues.prnActionsTaken,
            });

            showToast({
                message: 'Unscheduled task submitted successfully.',
                type: 'success',
                duration: 3000,
            });
            setIsUnscheduledTaskDialogOpen(false);
            resetFlow();
        } catch (error) {
            Sentry.captureException(error, {
                extra: {
                    formValues,
                },
            });
            showToast({
                message: 'Failed to submit unscheduled task, please try again.',
                type: 'error',
                duration: 3000,
            });
        }
    }, [branchId, formValues, setIsUnscheduledTaskDialogOpen]);

    return {
        step,
        setStep,
        shiftId,
        setShiftId,
        resident,
        setResident,
        isValid,
        formValues,
        resetFlow,
        progress,
        onSubmit,
        isSubmitPending: isSubmitUnscheduledTaskPending,
    };
}
