import { POINTS_STATUS } from '@allie/utils/src/constants/rewards.constants';
import { TASK_STATUS, TASK_TYPES } from '@allie/utils/src/constants/task-records.constants';

import { ResidentDailyTasksResponse } from './residents';

export type DailyTasksReadParams = {
    branchId?: number;
    date: string;
};

export interface DailyTasksShiftDetail extends ResidentDailyTasksResponse {
    resident: {
        id: number;
        firstName: string;
        lastName: string;
        photo: string;
        roomNumber: string;
        isOnHospice: boolean;
    };
}

type TasksByTab = {
    key: number;
    tabName: string;
    tasks: DailyTasksShiftDetail[] | ResidentDailyTasksResponse[];
};

export enum TabKey {
    LIVE_ALERTS,
    PENDING,
    COMPLETED,
}

export type DailyTasksByTab = TasksByTab[];

export type TabName = 'pending' | 'completed' | 'alerts';

type TasksByTabStrict = {
    key: TabKey;
    tabName: TabName;
    tasks: DailyTasksShiftDetail[];
};

export type DailyTasksByTabStrict = TasksByTabStrict[];

export type DailyTasksResponse = {
    [shift: string]: DailyTasksShiftDetail[];
};

export type DailyTasksCreateParams = {
    branchId: number;
    residentId: number;
    companyCarePlanId: number;
    date: string;
    shiftId: number;
};

export type DailyTaskCreateResponse = {
    status: number;
    response: {
        id: number;
        branchId: number;
        residentId: number;
        companyCarePlanId: number;
        date: string;
        shiftId: number;
        instructions: null;
        type: TASK_TYPES;
        status: TASK_STATUS;
        scheduledDatetimeUtc: string;
        shiftDayDate: string;
        shiftName: string;
        branchShiftId: 7;
        completedDatetimeUtc: string;
        completedUserId: id;
        userNotes: string;
        pointsAvailable: number;
        pointsClaimed: boolean;
        pointsStatus: POINTS_STATUS;
        source: string;
        sourceId: number;
        isActive: boolean;
        createdAt: string;
        updatedAt: string;
    };
};

export type TaskToUpdate = {
    taskId: number;
    taskStatusId: number;
    caregiverNotes: string | null;
};

export type UpdateDailyTasksParams = {
    tasks: TaskToUpdate[];
    branchId: number;
};

export type DailyTasksUpdateParams = {
    userId: number;
    taskStatusId: number;
    caregiverNotes?: string;
    residentProfileViewed?: boolean;
    taskNotesViewed?: boolean;
    taskCompletionSelection?: string;
};

export type AddTask = {
    residentId?: number;
    companyCarePlanId?: number;
    date: string;
    shift: number;
    startTime: Date | null;
    endTime: Date | null;
};

export type CheckTaskMode = 'Complete' | 'Reject' | 'Edit' | 'View';

export type TaskLoadingState = 'confirm' | 'reject' | 'disable' | null;

export type ResidentTasks = {
    id: number;
    firstName: string;
    lastName: string;
    photo: string;
    roomNumber: string;
    isOnHospice: boolean;
    tasks: DailyTasksShiftDetail[];
};

export type onConfirmMultipleTasksListInput = {
    taskId: number;
    taskStatusId: number;
}[];

export type DocumentationActionsParams = {
    branchId?: number;
    taskIds: number[];
};

export type TaskCompletionCount = {
    carePlanId: number;
    carePlanName: string;
    tasksCompleted: number;
};

export type AssistLevelChangeItem = {
    carePlanId: number;
    carePlanName: string;
    assistLevel: number;
};

export type AssistLevelChange = {
    [category: string]: AssistLevelChangeItem[];
};

export type RecentUnscheduledTasksResponse = {
    id: number;
    name: string;
};

export type DocumentationActionsResponse = {
    showNurseCallsActions: boolean;
    showTaskCompletionCount: TaskCompletionCount[];
    showAssistLevelChange: AssistLevelChange | Record<string, never>;
    recentUnscheduledTasks: RecentUnscheduledTasksResponse[];
};

export enum ExportTasksDataTypes {
    TASK_RECORDS = 'TASK_RECORDS',
    REGULATOR_FLOW_SHEET = 'REGULATOR_FLOW_SHEET',
}

export type ExportTaskRecordsCsvParams = {
    branchId: number;
    startDate: string;
    endDate: string;
    residentId?: number;
    type: ExportTasksDataTypes;
};
