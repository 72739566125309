import { useAtom } from 'jotai';
import { useSelector } from 'react-redux';

import { ReduxStore } from '~/types/redux';

import { unscheduledTaskCategoryAtom } from '../../../state/atom';
import withIcon from '../utils/withIcon';

import { useCategoryQuery } from './useCategoryQuery';

export default function useSelectCategoryStep() {
    const { branchId } = useSelector((state: ReduxStore) => state.session.sessionData);
    const [selectedCategory, setSelectedCategory] = useAtom(unscheduledTaskCategoryAtom);

    const { data: prnTaskCategories, isLoading, isError } = useCategoryQuery({ branchId: branchId! });

    return {
        prnTaskCategories: prnTaskCategories?.map(withIcon) ?? [],
        isLoading,
        isError,
        selectedCategory,
        setSelectedCategory,
    };
}
