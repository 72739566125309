import { useAtom } from 'jotai';
import { useFeatureFlagEnabled } from 'posthog-js/react';

import { unscheduledTaskModalOpenAtom } from '../state/atom';

export default function useNewPrnFlow() {
    const flagEnabled = useFeatureFlagEnabled('new-prn-flow');
    const [isUnscheduledTaskDialogOpen, setIsUnscheduledTaskDialogOpen] = useAtom(unscheduledTaskModalOpenAtom);

    return {
        isEnabled: flagEnabled ?? false,
        isUnscheduledTaskDialogOpen,
        setIsUnscheduledTaskDialogOpen,
    };
}
