import { atom } from 'jotai';
import { DateTime } from 'luxon';

import { ECall } from './types.d';

const currentShiftTasks = {
    shiftName: 'AM',
    tasks: [
        'Housekeeping - Full Assist',
        'Mobility - Cue / Reminders',
        'Grooming - Assist',
        'Dressing - Assist',
        'Bathing - Assist - 2xs per week',
    ],
};

const REQUESTED_AT = DateTime.now().minus({ seconds: 30, minutes: 6 });

const atomsMock: ECall.Alert[] = [
    {
        id: 1,
        requestedAt: REQUESTED_AT,
        resident: {
            id: 107,
            firstName: 'Michelle',
            lastName: 'Collinsworth',
            roomNumber: '204',
            photo: '107.jpeg',
        },
        status: ECall.AlertStatus.Unclaimed,
        unclaimedLevel: ECall.UnclaimedLevel.Level3,
        suggestedLocations: ['Dining Hall', 'Room 205'],
        currentShiftTasks,
        claimedCaregiver: null,
    },
    {
        id: 2,
        requestedAt: REQUESTED_AT,
        resident: {
            id: 106,
            firstName: 'Elenor',
            lastName: 'Thompson',
            roomNumber: '208',
            photo: '106.jpeg',
        },
        status: ECall.AlertStatus.Unclaimed,
        unclaimedLevel: ECall.UnclaimedLevel.Level1,
        suggestedLocations: ['Dining Room', 'Room 209'],
        currentShiftTasks,
        claimedCaregiver: null,
    },
    {
        id: 3,
        requestedAt: REQUESTED_AT,
        resident: {
            id: 109,
            firstName: 'Beatrice',
            lastName: 'Simmons',
            roomNumber: '218',
            photo: '109.jpeg',
        },
        status: ECall.AlertStatus.InProgress,
        unclaimedLevel: ECall.UnclaimedLevel.Level2,
        suggestedLocations: ['Dining Hall', 'Room 219'],
        currentShiftTasks,
        claimedCaregiver: {
            id: 1019,
            firstName: 'Stephen',
            lastName: 'Morrison',
        },
    },
    {
        id: 4,
        requestedAt: REQUESTED_AT,
        resident: {
            id: 99,
            firstName: 'Evelyn',
            lastName: 'Reynolds',
            roomNumber: '201',
            photo: '119.jpeg',
        },
        status: ECall.AlertStatus.ResidentReached,
        startedAt: DateTime.now(),
        unclaimedLevel: ECall.UnclaimedLevel.Level1,
        suggestedLocations: ['Dining Hall', 'Room 198'],
        currentShiftTasks,
        claimedCaregiver: {
            id: 1019,
            firstName: 'Stephen',
            lastName: 'Morisson',
        },
    },
    {
        id: 5,
        requestedAt: REQUESTED_AT,
        resident: {
            id: 103,
            firstName: 'Irene',
            lastName: 'Williams',
            roomNumber: '203',
            photo: '110.jpeg',
        },
        status: ECall.AlertStatus.Claimed,
        unclaimedLevel: ECall.UnclaimedLevel.Level3,
        suggestedLocations: ['Dining Hall', 'Room 205'],
        currentShiftTasks,
        claimedCaregiver: {
            id: 1018,
            firstName: 'Jessie',
            lastName: 'Lucci',
        },
    },
    {
        id: 6,
        requestedAt: REQUESTED_AT,
        resident: {
            id: 108,
            firstName: 'Walter',
            lastName: 'Davis',
            roomNumber: '209',
            photo: '108.jpeg',
        },
        status: ECall.AlertStatus.Claimed,
        unclaimedLevel: ECall.UnclaimedLevel.Level3,
        suggestedLocations: ['Dining Hall', 'Room 198'],
        currentShiftTasks,
        claimedCaregiver: {
            id: 1020,
            firstName: 'Jane',
            lastName: 'Doe',
        },
    },
];

export const alertsAtom = atom(atomsMock);
